import React, { useEffect, useState } from "react";
import { auth, db } from "./firebase";
import { doc, getDoc } from "firebase/firestore";
import Login from "./Login";
import Dashboard from "./Dashboard";
import ApplicationForm from "./ApplicationForm";
import EmployeeInterview from "./EmployeeInterview";
import CompleteApplication from "./CompleteApplication";
import NonCompeteAgreement from "./NonCompeteAgreement";
import UniquePunch from "./UniquePunch";
import PasswordReset from "./PasswordReset";
import { UserProvider } from "./UserContext";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";
import InspectionForm from "./InspectionForm";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { FormProvider } from "./FormContext";
import { GoogleMapsProvider } from "./GoogleMapsProvider";

const queryClient = new QueryClient();

function App() {
  const [user, setUser] = useState(null);
  const [role, setRole] = useState(null);
  const [applicationComplete] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (userAuth) => {
      setUser(userAuth);

      if (userAuth) {
        const userDoc = await getDoc(doc(db, "users", userAuth.uid));
        if (userDoc.exists()) {
          setRole(userDoc.data().role);
        } else {
          console.error(
            "User data not found. Please contact your administrator."
          );
        }
      } else {
        setRole(null);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [setUser, setRole]);

  function RenderRoutes() {
    let navigate = useNavigate();

    useEffect(() => {
      if (
        applicationComplete &&
        window.location.pathname.includes("/complete-application")
      ) {
        navigate("/dashboard");
      }
    }, [navigate]);

    return (
      <UserProvider>
        <Routes>
          <Route
            path="/login"
            element={
              !user ? (
                <Login />
              ) : role === "supervisor" ? (
                <Navigate to="/dashboard/vincollect" />
              ) : role === "owner" ||
                role === "manager" ||
                role === "admin" ||
                role === "director" ||
                role === "hr" ||
                role === "consultant" ||
                role === "recruiter" ? (
                <Navigate to="/dashboard" />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/dashboard/*"
            element={
              user &&
              (role === "owner" ||
                role === "manager" ||
                role === "admin" ||
                role === "director" ||
                role === "hr" ||
                role === "consultant" ||
                role === "recruiter" ||
                role === "supervisor") ? (
                <Dashboard userRole={role} />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route path="/auth/action" element={<PasswordReset />} />
          <Route
            path="/apply"
            element={
              <FormProvider>
                <ApplicationForm />
              </FormProvider>
            }
          />
          <Route path="/inspection" element={<InspectionForm />} />
          <Route
            path="/non-compete-agreement"
            element={<NonCompeteAgreement />}
          />
          <Route path="/unique-punch" element={<UniquePunch />} />
          <Route
            path="/complete-application/:uid"
            element={<CompleteApplication />}
          />
          <Route path="/interview/:id" element={<EmployeeInterview />} />
          <Route path="/*" element={<Navigate to="/login" />} />
        </Routes>
      </UserProvider>
    );
  }

  return (
    <QueryClientProvider client={queryClient}>
      <GoogleMapsProvider>
        <Router>
          <div className="App">
            <RenderRoutes />
          </div>
        </Router>
      </GoogleMapsProvider>
    </QueryClientProvider>
  );
}

export default App;
