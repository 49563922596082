import React, { useState, useMemo, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  flexRender,
} from "@tanstack/react-table";
import { db } from "./firebase";
import {
  collection,
  getDocs,
  doc,
  getDoc,
  updateDoc,
  Timestamp,
  onSnapshot,
} from "firebase/firestore";
import { NumericFormat } from "react-number-format";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faClock,
  faMapMarkerAlt,
  faRoad,
  faBandAid,
  faCar,
  faFileAlt,
  faExclamationTriangle,
  faDollarSign,
  faCheckCircle,
  faImage,
  faTimes,
  faUser,
  faFingerprint,
} from "@fortawesome/free-solid-svg-icons";
import { useSearchParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AccidentStats from "./AccidentStats";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const fetchAccidents = async (startDate, endDate) => {
  const accidentsCollection = collection(db, "accidents");
  const accidentsSnapshot = await getDocs(accidentsCollection);
  const accidentsList = accidentsSnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  // Only filter by date if both dates are selected
  if (startDate && endDate) {
    return accidentsList.filter((accident) => {
      const accidentDate = new Date(accident.date.seconds * 1000);

      // For same-day selection, compare only the date parts
      if (startDate.toDateString() === endDate.toDateString()) {
        return accidentDate.toDateString() === startDate.toDateString();
      }

      // For date ranges, use the existing comparison
      return accidentDate >= startDate && accidentDate <= endDate;
    });
  }

  // Return all accidents if no date range is selected
  return accidentsList;
};

function AccidentDetails({ accident, onClose, userName }) {
  const [expandedImage, setExpandedImage] = useState(null);
  const [drivers, setDrivers] = useState([]);
  const [faultData, setFaultData] = useState({
    fault: accident?.fault || "undetermined",
    faultDetermination: accident?.faultDetermination || null,
  });
  const [costEstimation, setCostEstimation] = useState({
    amount: accident?.costEstimation?.amount || "",
    currency: accident?.costEstimation?.currency || "USD",
    estimatedBy: accident?.costEstimation?.estimatedBy || null,
    estimatedAt: accident?.costEstimation?.estimatedAt || null,
  });
  const [tempCostEstimation, setTempCostEstimation] = useState(
    accident?.costEstimation?.amount || ""
  );
  const [approvalData, setApprovalData] = useState({
    approved: accident?.approved || false,
    approvedBy: accident?.approvedBy || null,
    approvedAt: accident?.approvedAt || null,
  });
  const [actualCost, setActualCost] = useState({
    amount: accident?.actualCost?.amount || "",
    currency: accident?.actualCost?.currency || "USD",
    addedBy: accident?.actualCost?.addedBy || null,
    addedAt: accident?.actualCost?.addedAt || null,
  });
  const [tempActualCost, setTempActualCost] = useState(
    accident?.actualCost?.amount || ""
  );

  useEffect(() => {
    // Update costEstimation and tempCostEstimation when accident prop changes
    setCostEstimation({
      amount: accident?.costEstimation?.amount || "",
      currency: accident?.costEstimation?.currency || "USD",
      estimatedBy: accident?.costEstimation?.estimatedBy || null,
      estimatedAt: accident?.costEstimation?.estimatedAt || null,
    });
    setTempCostEstimation(accident?.costEstimation?.amount || "");

    if (
      accident &&
      accident.driversInvolved &&
      Array.isArray(accident.driversInvolved)
    ) {
      const fetchDrivers = async () => {
        const driversData = await Promise.all(
          accident.driversInvolved
            .filter((driverUid) => driverUid)
            .map(async (driverUid) => {
              try {
                const userDoc = await getDoc(doc(db, "users", driverUid));
                if (userDoc.exists()) {
                  const userData = userDoc.data();
                  return {
                    uid: driverUid,
                    firstName: userData.firstName,
                    lastName: userData.lastName,
                  };
                }
                return {
                  uid: driverUid,
                  firstName: "Unknown",
                  lastName: "User",
                };
              } catch (error) {
                console.error(`Error fetching driver ${driverUid}:`, error);
                return {
                  uid: driverUid,
                  firstName: "Unknown",
                  lastName: "User",
                };
              }
            })
        );
        setDrivers(driversData);
      };

      fetchDrivers();
    } else {
      setDrivers([]);
    }

    // Set up real-time listener for fault data
    const unsubscribe = onSnapshot(doc(db, "accidents", accident.id), (doc) => {
      const updatedData = doc.data();
      setFaultData({
        fault: updatedData.fault || "undetermined",
        faultDetermination: updatedData.faultDetermination || null,
      });
      const updatedCostEstimation = {
        amount: updatedData.costEstimation?.amount || "",
        currency: updatedData.costEstimation?.currency || "USD",
        estimatedBy: updatedData.costEstimation?.estimatedBy || null,
        estimatedAt: updatedData.costEstimation?.estimatedAt || null,
      };
      setCostEstimation(updatedCostEstimation);
      setTempCostEstimation(updatedCostEstimation.amount);
      setApprovalData({
        approved: updatedData.approved || false,
        approvedBy: updatedData.approvedBy || null,
        approvedAt: updatedData.approvedAt || null,
      });
      setActualCost({
        amount: updatedData.actualCost?.amount || "",
        currency: updatedData.actualCost?.currency || "USD",
        addedBy: updatedData.actualCost?.addedBy || null,
        addedAt: updatedData.actualCost?.addedAt || null,
      });
      setTempActualCost(updatedData.actualCost?.amount || "");
    });

    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, [accident]);

  if (!accident) {
    return null;
  }

  const formatDateTime = (timestamp) => {
    const date = new Date(timestamp.seconds * 1000);
    return {
      date: date.toLocaleDateString(),
      time: date.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      }),
    };
  };

  const { date, time } = formatDateTime(accident.date);

  const handleFaultChange = async (event) => {
    const newFault = event.target.value;

    try {
      const accidentRef = doc(db, "accidents", accident.id);
      let updateData = { fault: newFault };

      if (newFault !== "undetermined") {
        const newFaultDetermination = {
          fault: newFault,
          determinedBy: userName,
          determinedAt: Timestamp.now(),
        };
        updateData.faultDetermination = newFaultDetermination;
      } else {
        updateData.faultDetermination = null;
      }

      await updateDoc(accidentRef, updateData);
      // The state will be updated by the onSnapshot listener
    } catch (error) {
      console.error("Error updating fault determination:", error);
      // You might want to show an error message to the user here
    }
  };

  const handleCostEstimationChange = (values) => {
    setTempCostEstimation(values.value);
  };

  const handleCostEstimationSubmit = async () => {
    try {
      const accidentRef = doc(db, "accidents", accident.id);
      const updateData = {
        costEstimation: {
          amount: tempCostEstimation,
          currency: "USD",
          estimatedBy: userName,
          estimatedAt: Timestamp.now(),
        },
      };

      await updateDoc(accidentRef, updateData);
      // The state will be updated by the onSnapshot listener
    } catch (error) {
      console.error("Error updating cost estimation:", error);
      // You might want to show an error message to the user here
    }
  };

  const handleApproval = async () => {
    try {
      const accidentRef = doc(db, "accidents", accident.id);
      const updateData = {
        approved: true,
        approvedBy: userName,
        approvedAt: Timestamp.now(),
      };

      await updateDoc(accidentRef, updateData);
      // The state will be updated by the onSnapshot listener
    } catch (error) {
      console.error("Error updating approval:", error);
      // You might want to show an error message to the user here
    }
  };

  const handleActualCostChange = (values) => {
    setTempActualCost(values.value);
  };

  const handleActualCostSubmit = async () => {
    try {
      const accidentRef = doc(db, "accidents", accident.id);
      const updateData = {
        actualCost: {
          amount: tempActualCost,
          currency: "USD",
          addedBy: userName,
          addedAt: Timestamp.now(),
        },
      };

      await updateDoc(accidentRef, updateData);
    } catch (error) {
      console.error("Error updating actual cost:", error);
    }
  };

  return (
    <div
      className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
      onClick={onClose}
    >
      <div
        className="relative top-20 mx-auto p-8 border w-11/12 md:w-3/4 lg:w-2/3 shadow-lg rounded-lg bg-white"
        onClick={(e) => e.stopPropagation()}
      >
        <h2 className="text-3xl font-bold text-gray-900 mb-6 flex items-center">
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            className="text-yellow-500 mr-3"
          />
          Accident Details
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="bg-gray-50 p-6 rounded-lg shadow">
            <h3 className="text-xl font-semibold text-gray-800 mb-4 flex items-center">
              <FontAwesomeIcon
                icon={faFileAlt}
                className="text-blue-500 mr-2"
              />
              General Information
            </h3>
            <InfoItem icon={faCalendarAlt} label="Date" value={date} />
            <InfoItem icon={faClock} label="Time" value={time} />
            <InfoItem
              icon={faUser}
              label="Supervisor"
              value={accident.supervisorName || "Not assigned"}
            />
            <InfoItem
              icon={faMapMarkerAlt}
              label="Location"
              value={accident.location}
            />
            <InfoItem
              icon={faRoad}
              label="Site of Accident"
              value={accident.siteOfAccident}
            />
            <InfoItem
              icon={faBandAid}
              label="Any Injuries"
              value={accident.anyInjuries ? "Yes" : "No"}
            />
          </div>

          <div className="bg-gray-50 p-6 rounded-lg shadow">
            <h3 className="text-xl font-semibold text-gray-800 mb-4 flex items-center">
              <FontAwesomeIcon icon={faCar} className="text-green-500 mr-2" />
              Vehicles Involved
            </h3>
            {accident.vehiclesInvolved.map((vehicle, index) => (
              <div
                key={index}
                className="mb-4 p-4 bg-white rounded-lg shadow-sm"
              >
                <h4 className="font-medium text-gray-800 mb-2">
                  Vehicle {index + 1}
                </h4>
                <InfoItem
                  icon={faCar}
                  label="License Plate"
                  value={`${vehicle.licensePlate} (${vehicle.state})`}
                />
                <InfoItem
                  icon={faFingerprint}
                  label="VIN"
                  value={vehicle.vin}
                />
              </div>
            ))}
          </div>

          <div className="bg-gray-50 p-6 rounded-lg shadow">
            <h3 className="text-xl font-semibold text-gray-800 mb-4 flex items-center">
              <FontAwesomeIcon icon={faBandAid} className="text-red-500 mr-2" />
              Drivers Involved
            </h3>
            {drivers.map((driver, index) => (
              <div
                key={driver.uid}
                className="mb-4 p-4 bg-white rounded-lg shadow-sm"
              >
                <h4 className="font-medium text-gray-800 mb-2">
                  Driver {index + 1}
                </h4>
                <InfoItem
                  icon={faUser}
                  label="Name"
                  value={`${driver.firstName} ${driver.lastName}`}
                />
              </div>
            ))}
          </div>

          <div className="bg-gray-50 p-6 rounded-lg shadow">
            <h3 className="text-xl font-semibold text-gray-800 mb-4 flex items-center">
              <FontAwesomeIcon
                icon={faFileAlt}
                className="text-blue-500 mr-2"
              />
              Accident Description - Supervisor's Notes
            </h3>
            <p className="text-gray-800 whitespace-pre-wrap">
              {accident.accidentDescription
                ? accident.accidentDescription
                : "No description provided"}
            </p>
          </div>

          <div className="bg-gray-50 p-6 rounded-lg shadow">
            <h3 className="text-xl font-semibold text-gray-800 mb-4 flex items-center">
              <FontAwesomeIcon
                icon={faFileAlt}
                className="text-blue-500 mr-2"
              />
              Accident Description - Driver
            </h3>
            <p className="text-gray-800 whitespace-pre-wrap">
              {accident.accidentDescriptionDriver
                ? accident.accidentDescriptionDriver
                : "No description provided"}
            </p>
          </div>

          <div className="bg-gray-50 p-6 rounded-lg shadow">
            <h3 className="text-xl font-semibold text-gray-800 mb-4 flex items-center">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="text-red-500 mr-2"
              />
              Fault Determination
            </h3>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Fault:
              </label>
              <select
                value={faultData.fault}
                onChange={handleFaultChange}
                className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              >
                <option value="undetermined">Undetermined</option>
                <option value="our-fault">Our Fault</option>
                <option value="not-our-fault">Not Our Fault</option>
              </select>
            </div>
            {faultData.faultDetermination && (
              <div className="mt-4 p-4 bg-white rounded-lg shadow-sm">
                <h4 className="font-medium text-gray-800 mb-2">
                  Fault Determination Details
                </h4>
                <InfoItem
                  icon={faUser}
                  label="Determined By"
                  value={faultData.faultDetermination.determinedBy}
                />
                <InfoItem
                  icon={faCalendarAlt}
                  label="Determined At"
                  value={
                    formatDateTime(faultData.faultDetermination.determinedAt)
                      .date +
                    " " +
                    formatDateTime(faultData.faultDetermination.determinedAt)
                      .time
                  }
                />
              </div>
            )}
          </div>

          {/* Only show cost estimation if fault is determined as "our-fault" */}
          {faultData.fault === "our-fault" && (
            <div className="bg-gray-50 p-6 rounded-lg shadow">
              <h3 className="text-xl font-semibold text-gray-800 mb-4 flex items-center">
                <FontAwesomeIcon
                  icon={faDollarSign}
                  className="text-green-500 mr-2"
                />
                Cost Estimation
              </h3>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Estimated Cost (USD):
                </label>
                <div className="flex items-center">
                  <NumericFormat
                    value={tempCostEstimation}
                    onValueChange={handleCostEstimationChange}
                    thousandSeparator={true}
                    prefix={"$"}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Enter estimated cost"
                  />
                  <button
                    onClick={handleCostEstimationSubmit}
                    className="ml-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  >
                    Confirm
                  </button>
                </div>
              </div>
              {costEstimation.estimatedBy && (
                <div className="mt-4 p-4 bg-white rounded-lg shadow-sm">
                  <h4 className="font-medium text-gray-800 mb-2">
                    Cost Estimation Details
                  </h4>
                  <InfoItem
                    icon={faDollarSign}
                    label="Estimated Cost"
                    value={`$${parseFloat(costEstimation.amount).toFixed(2)}`}
                  />
                  <InfoItem
                    icon={faUser}
                    label="Estimated By"
                    value={costEstimation.estimatedBy}
                  />
                  <InfoItem
                    icon={faCalendarAlt}
                    label="Estimated At"
                    value={
                      costEstimation.estimatedAt
                        ? formatDateTime(costEstimation.estimatedAt).date +
                          " " +
                          formatDateTime(costEstimation.estimatedAt).time
                        : "N/A"
                    }
                  />
                </div>
              )}
            </div>
          )}

          {/* Show actual cost input only if cost has been estimated */}
          {costEstimation.amount && faultData.fault === "our-fault" && (
            <div className="bg-gray-50 p-6 rounded-lg shadow">
              <h3 className="text-xl font-semibold text-gray-800 mb-4 flex items-center">
                <FontAwesomeIcon
                  icon={faDollarSign}
                  className="text-red-500 mr-2"
                />
                Actual Cost
              </h3>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Actual Cost (USD):
                </label>
                <div className="flex items-center">
                  <NumericFormat
                    value={tempActualCost}
                    onValueChange={handleActualCostChange}
                    thousandSeparator={true}
                    prefix={"$"}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Enter actual cost"
                  />
                  <button
                    onClick={handleActualCostSubmit}
                    className="ml-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  >
                    Confirm
                  </button>
                </div>
              </div>
              {actualCost.addedBy && (
                <div className="mt-4 p-4 bg-white rounded-lg shadow-sm">
                  <h4 className="font-medium text-gray-800 mb-2">
                    Actual Cost Details
                  </h4>
                  <InfoItem
                    icon={faDollarSign}
                    label="Actual Cost"
                    value={`$${parseFloat(actualCost.amount).toFixed(2)}`}
                  />
                  <InfoItem
                    icon={faUser}
                    label="Added By"
                    value={actualCost.addedBy}
                  />
                  <InfoItem
                    icon={faCalendarAlt}
                    label="Added At"
                    value={
                      actualCost.addedAt
                        ? formatDateTime(actualCost.addedAt).date +
                          " " +
                          formatDateTime(actualCost.addedAt).time
                        : "N/A"
                    }
                  />
                </div>
              )}
            </div>
          )}

          {/* Only show approval section if actual cost has been added */}
          {actualCost.amount && faultData.fault === "our-fault" && (
            <div className="bg-gray-50 p-6 rounded-lg shadow">
              <h3 className="text-xl font-semibold text-gray-800 mb-4 flex items-center">
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="text-blue-500 mr-2"
                />
                Approval
              </h3>
              {!approvalData.approved ? (
                <button
                  onClick={handleApproval}
                  className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Approve Accident
                </button>
              ) : (
                <div className="mt-4 p-4 bg-white rounded-lg shadow-sm">
                  <h4 className="font-medium text-gray-800 mb-2">
                    Approval Details
                  </h4>
                  <InfoItem
                    icon={faUser}
                    label="Approved By"
                    value={approvalData.approvedBy}
                  />
                  <InfoItem
                    icon={faCalendarAlt}
                    label="Approved At"
                    value={
                      approvalData.approvedAt
                        ? formatDateTime(approvalData.approvedAt).date +
                          " " +
                          formatDateTime(approvalData.approvedAt).time
                        : "N/A"
                    }
                  />
                </div>
              )}
            </div>
          )}

          {accident.vehicleImages && (
            <div className="col-span-2 bg-gray-50 p-6 rounded-lg shadow mt-8">
              <h3 className="text-xl font-semibold text-gray-800 mb-4 flex items-center">
                <FontAwesomeIcon
                  icon={faImage}
                  className="text-purple-500 mr-2"
                />
                Images
              </h3>
              <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
                {accident.vehicleImages
                  .flatMap((vi) => vi.imageUrls)
                  .map((url, index) => (
                    <img
                      key={index}
                      src={url}
                      alt={`Accident ${index + 1}`}
                      className="w-full h-32 object-cover rounded-md cursor-pointer hover:opacity-80 transition-opacity shadow"
                      onClick={() => setExpandedImage(url)}
                    />
                  ))}
              </div>
            </div>
          )}
        </div>

        <div className="mt-8 text-center">
          <button
            className="px-6 py-2 bg-blue-500 text-white text-base font-medium rounded-md shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300 transition-colors"
            onClick={onClose}
          >
            Close
          </button>
        </div>

        {expandedImage && (
          <div
            className="fixed inset-0 bg-black bg-opacity-90 flex justify-center items-center z-50"
            onClick={(e) => {
              e.stopPropagation();
              setExpandedImage(null);
            }}
          >
            <img
              src={expandedImage}
              alt="Expanded view"
              className="max-w-full max-h-full object-contain"
            />
            <button
              className="absolute top-4 right-4 text-white text-2xl"
              onClick={() => setExpandedImage(null)}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

// Updated InfoItem component
function InfoItem({ icon, label, value }) {
  return (
    <div className="mb-3 flex items-center">
      <FontAwesomeIcon icon={icon} className="text-gray-500 mr-2 w-5" />
      <span className="font-medium text-gray-600">{label}:</span>
      <span className="text-gray-800 ml-2">{value}</span>
    </div>
  );
}

// Add loading state to CostSummary
function CostSummary({ accidents, isLoading }) {
  const costs = useMemo(() => {
    return accidents.reduce(
      (acc, accident) => {
        if (accident.fault === "our-fault") {
          // Add estimated cost if it exists
          if (accident.costEstimation?.amount) {
            acc.estimated += parseFloat(accident.costEstimation.amount) || 0;
            acc.estimatedCount++;
          }
          // Add actual cost if it exists
          if (accident.actualCost?.amount) {
            acc.actual += parseFloat(accident.actualCost.amount) || 0;
            acc.actualCount++;
          }
        }
        return acc;
      },
      { estimated: 0, actual: 0, estimatedCount: 0, actualCount: 0 }
    );
  }, [accidents]);

  if (isLoading) {
    return (
      <div className="flex space-x-6">
        {[1, 2].map((i) => (
          <div key={i} className="bg-gray-700 rounded-lg p-4">
            <Skeleton
              height={16}
              width={150}
              className="mb-1"
              baseColor="#374151"
              highlightColor="#4B5563"
            />
            <Skeleton
              height={24}
              width={100}
              baseColor="#374151"
              highlightColor="#4B5563"
            />
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="flex space-x-6">
      <div className="bg-gray-700 rounded-lg p-4">
        <div className="text-sm text-gray-300 mb-1">
          Estimated Cost ({costs.estimatedCount} accidents)
        </div>
        <div className="text-xl font-bold text-white">
          $
          {costs.estimated.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      </div>
      <div className="bg-gray-700 rounded-lg p-4">
        <div className="text-sm text-gray-300 mb-1">
          Actual Cost ({costs.actualCount} accidents)
        </div>
        <div className="text-xl font-bold text-white">
          $
          {costs.actual.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      </div>
    </div>
  );
}

// Add loading state for the table
function TableSkeleton() {
  return (
    <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
      <div className="bg-gray-100 border-b-2 border-gray-200">
        <div className="px-5 py-3 flex space-x-4">
          {[1, 2, 3, 4, 5, 6].map((i) => (
            <Skeleton
              key={i}
              height={16}
              width={100}
              baseColor="#E5E7EB"
              highlightColor="#F3F4F6"
            />
          ))}
        </div>
      </div>
      <div className="bg-white">
        {[1, 2, 3, 4, 5].map((row) => (
          <div
            key={row}
            className="px-5 py-5 border-b border-gray-200 flex space-x-4"
          >
            {[1, 2, 3, 4, 5, 6].map((col) => (
              <Skeleton
                key={col}
                height={16}
                width={100}
                baseColor="#F9FAFB"
                highlightColor="#F3F4F6"
              />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

function Accidents({ userRole }) {
  const [searchParams] = useSearchParams();
  const [selectedAccident, setSelectedAccident] = useState(null);
  const [sorting, setSorting] = useState([
    {
      id: "Submission Date",
      desc: true,
    },
  ]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [searchText, setSearchText] = useState("");

  const {
    data: accidents,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["accidents", startDate, endDate],
    queryFn: () => fetchAccidents(startDate, endDate),
    enabled: true, // Always enabled now
  });
  const [userName, setUserName] = useState(() => {
    const firstName = localStorage.getItem("userFirstName") || "";
    const lastName = localStorage.getItem("userLastName") || "";
    return `${firstName} ${lastName}`.trim();
  });

  useEffect(() => {
    const storedFirstName = localStorage.getItem("userFirstName");
    const storedLastName = localStorage.getItem("userLastName");
    if (storedFirstName || storedLastName) {
      setUserName(`${storedFirstName} ${storedLastName}`.trim());
    }
  }, []);

  useEffect(() => {
    const accidentId = searchParams.get("id");
    if (accidentId && accidents) {
      const accident = accidents.find((acc) => acc.id === accidentId);
      if (accident) {
        setSelectedAccident(accident);
      }
    }
  }, [searchParams, accidents]);

  const handleViewDetails = (accident) => {
    setSelectedAccident(accident);
  };

  const filteredAccidents = useMemo(() => {
    if (!accidents) return [];

    return accidents.filter((accident) => {
      // If no search text or undefined, return all accidents
      if (!searchText || searchText === "") return true;

      // Split search terms by spaces and filter out empty strings
      const searchTerms = searchText.toLowerCase().split(/\s+/).filter(Boolean);

      // Check if any of the search terms match any of the fields
      return searchTerms.some(term => {
        // Safely access and convert strings to lowercase, defaulting to empty string if undefined
        const location = (accident.location || "").toLowerCase();
        const supervisor = (accident.supervisorName || "").toLowerCase();
        const site = (accident.siteOfAccident || "").toLowerCase();
        
        // Check if the term is included in any of the fields
        const locationMatch = location.includes(term);
        const supervisorMatch = supervisor.includes(term);
        const siteMatch = site.includes(term);
        
        return locationMatch || supervisorMatch || siteMatch;
      });
    });
  }, [accidents, searchText]);

  const columns = useMemo(
    () => [
      {
        header: "Status",
        accessorFn: (row) => {
          // First determine the status string
          let status;
          if (row.approved) {
            status = "Approved";
          } else if (row.fault === "not-our-fault") {
            status = "Fault Determined"; // Changed from "Cost Added"
          } else if (row.actualCost?.amount) {
            status = "Cost Added";
          } else if (row.costEstimation?.amount) {
            status = "Cost Estimated";
          } else if (row.fault && row.fault !== "undetermined") {
            status = "Fault Determined";
          } else {
            status = "Pending Review";
          }
          
          // Return just the status string for sorting
          return status;
        },
        cell: ({ getValue }) => {
          // Define color based on status
          const status = getValue();
          const getStatusColor = (status) => {
            switch (status) {
              case "Approved":
                return "bg-green-100 text-green-800";
              case "Cost Added":
                return "bg-blue-100 text-blue-800";
              case "Cost Estimated":
                return "bg-purple-100 text-purple-800";
              case "Fault Determined":
                return "bg-yellow-100 text-yellow-800";
              default:
                return "bg-gray-100 text-gray-800";
            }
          };

          return (
            <span
              className={`px-2 py-1 rounded-full text-xs font-semibold ${getStatusColor(status)}`}
            >
              {status}
            </span>
          );
        },
      },
      {
        header: "Submission Date",
        accessorFn: (row) => {
          if (row.timeOfSubmission && row.timeOfSubmission.seconds) {
            return new Date(
              row.timeOfSubmission.seconds * 1000
            ).toLocaleDateString();
          }
          return "N/A";
        },
      },
      {
        header: "Date",
        accessorFn: (row) =>
          new Date(row.date.seconds * 1000).toLocaleDateString(),
      },
      {
        header: "Supervisor",
        accessorKey: "supervisorName",
      },
      {
        header: "Location",
        accessorKey: "location",
      },
      {
        header: "Site of Accident",
        accessorKey: "siteOfAccident",
      },
      ...(userRole === "owner" || userRole === "consultant"
        ? [
            {
              header: "Actions",
              cell: ({ row }) => (
                <button
                  onClick={() => handleViewDetails(row.original)}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded"
                >
                  View Details
                </button>
              ),
            },
          ]
        : []),
    ],
    [userRole]
  );

  const table = useReactTable({
    data: filteredAccidents || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
    onSortingChange: setSorting,
  });

  if (isLoading) {
    return (
      <div className="container mx-auto px-4 sm:px-8">
        <div className="py-8">
          <Skeleton
            height={24}
            width={200}
            className="mb-6"
            baseColor="#374151"
            highlightColor="#4B5563"
          />

          <div className="my-6 p-6 bg-gray-800 rounded-lg shadow-lg">
            <div className="flex flex-col space-y-6">
              {/* Cost Summary Loading State */}
              <CostSummary isLoading={true} accidents={[]} />

              {/* AccidentStats Loading State */}
              <AccidentStats accidents={[]} isLoading={true} />

              {/* Filters Loading State */}
              <div className="flex flex-row items-end space-x-4">
                <div className="flex flex-col gap-2">
                  <Skeleton
                    height={16}
                    width={80}
                    className="mb-2"
                    baseColor="#374151"
                    highlightColor="#4B5563"
                  />
                  <Skeleton
                    height={40}
                    width={288}
                    baseColor="#374151"
                    highlightColor="#4B5563"
                  />
                </div>
                <div className="flex flex-col gap-2 flex-1">
                  <Skeleton
                    height={16}
                    width={80}
                    className="mb-2"
                    baseColor="#374151"
                    highlightColor="#4B5563"
                  />
                  <Skeleton
                    height={40}
                    baseColor="#374151"
                    highlightColor="#4B5563"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
            <TableSkeleton />
          </div>
        </div>
      </div>
    );
  }
  if (error)
    return (
      <div className="text-red-500 text-center">Error: {error.message}</div>
    );

  return (
    <div className="container mx-auto px-4 sm:px-8">
      <div className="py-8">
        <div>
          <h1 className="text-lg font-bold text-white uppercase tracking-wider">
            Accidents Report
          </h1>
        </div>

        <div className="my-6 p-6 bg-gray-800 rounded-lg shadow-lg">
          <div className="flex flex-col space-y-6">
            {/* Cost Summary */}
            <CostSummary accidents={filteredAccidents} />

            {/* Add AccidentStats component here */}
            <AccidentStats accidents={filteredAccidents} />

            {/* Filters */}
            <div className="flex flex-row items-end space-x-4">
              <div className="flex flex-col gap-2">
                <label className="text-sm font-semibold text-gray-300">
                  Date Range
                </label>
                <DatePicker
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(update) => {
                    setDateRange(update);
                    // Clear the data if either date is deselected
                    if (!update[0] || !update[1]) {
                      // Optional: Reset the data when range is incomplete
                      // queryClient.setQueryData(["accidents"], []);
                    }
                  }}
                  className="w-72 p-3 bg-gray-700 border border-gray-600 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
                  placeholderText="Select date range"
                  dateFormat="MMM dd, yyyy"
                  required
                />
              </div>

              <div className="flex flex-col gap-2 flex-1">
                <label className="text-sm font-semibold text-gray-300">
                  Search
                </label>
                <input
                  type="text"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  placeholder="Search location, supervisor, or site..."
                  className="w-full p-3 bg-gray-700 border border-gray-600 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
          <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
            <table className="min-w-full leading-normal">
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <th
                        key={header.id}
                        onClick={header.column.getToggleSortingHandler()}
                        className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        <span>
                          {{
                            asc: " 🔼",
                            desc: " 🔽",
                          }[header.column.getIsSorted()] ?? null}
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <td
                        key={cell.id}
                        className="px-5 py-5 border-b border-gray-200 bg-white text-sm"
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {selectedAccident && (
        <AccidentDetails
          accident={selectedAccident}
          onClose={() => setSelectedAccident(null)}
          userName={userName}
        />
      )}
    </div>
  );
}

export default Accidents;
