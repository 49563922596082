import React, { useState, useEffect, useContext } from "react";
import { auth, db } from "./firebase";
import {
  Link,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from "react-router-dom";
import {
  doc,
  collection,
  query,
  where,
  getDocs,
  getDoc,
} from "firebase/firestore";
import UserDirectory from "./UserDirectory";
import useRequireNCASignature from "./useRequireNCASignature";
import ApplicationReview from "./ApplicationReview";
import Rejected from "./Rejected";
import Locations from "./Locations";
import Reports from "./Reports";
import Salary from "./Salary";
import Invoices from "./Invoices";
import Accidents from "./Accidents";
import Tickets from "./Tickets";
import VinCollect from "./VinCollect";
import Timesheets from "./Timesheet";
import Statistics from "./Statistics";
import TimeOff from "./TimeOff";
import Concerns from "./Concerns";
import Compendium from "./Compendium";
import Transactions from "./Transactions";
import Purgatory from "./Purgatory";
import BirthdayFlyout from "./BirthdayFlyout";
import { useUser } from "./UserContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBan,
  faUsers,
  faInbox,
  faTimesCircle,
  faUserClock,
  faLocationDot,
  faBarsStaggered,
  faRightFromBracket,
  faFileInvoiceDollar,
  faFileInvoice,
  faFireAlt,
  faCakeCandles,
  faClock,
  faMoneyCheckDollar,
  faRightLeft,
  faCalendarDays,
  faCarCrash,
  faSprayCanSparkles,
  faTicket,
  faAddressBook,
  faChartLine,
  faHandshake,
} from "@fortawesome/free-solid-svg-icons";
import { useQuery } from "@tanstack/react-query";
import Odometer from "react-odometerjs";
import "odometer/themes/odometer-theme-default.css";
import { GoogleMapsContext } from "./GoogleMapsProvider";

function Dashboard({ userRole }) {
  const [userFullName, setUserFullName] = useState(() => {
    const firstName = localStorage.getItem("userFirstName") || "";
    const lastName = localStorage.getItem("userLastName") || "";
    return `${firstName} ${lastName}`.trim();
  });
  const [greeting, setGreeting] = useState("");
  const { setUserName } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isFlyoutOpen, setIsFlyoutOpen] = useState(false);

  const [counterStates, setCounterStates] = useState({
    applicationCount: { isLoading: true, value: 0 },
    activeUserCount: { isLoading: true, value: 0 },
    inactiveUserCount: { isLoading: true, value: 0 },
    terminatedUserCount: { isLoading: true, value: 0 },
    locationsCount: { isLoading: true, value: 0 },
    ticketCount: { isLoading: true, value: 0 },
  });

  const { data: applicationCount } = useQuery({
    queryKey: ["applicationCount"],
    queryFn: () => fetchCounts("applications"),
    enabled:
      userRole &&
      [
        "owner",
        "manager",
        "admin",
        "director",
        "supervisor",
        "hr",
        "consultant",
        "recruiter",
      ].includes(userRole),
  });

  const { data: activeUserCount } = useQuery({
    queryKey: ["activeUserCount"],
    queryFn: () => fetchCounts("users", where("status", "==", "Active")),
    enabled:
      userRole &&
      [
        "owner",
        "manager",
        "admin",
        "director",
        "supervisor",
        "hr",
        "consultant",
        "recruiter",
      ].includes(userRole),
  });

  const { data: ticketCount } = useQuery({
    queryKey: ["ticketCount"],
    queryFn: () => fetchCounts("tickets"),
    enabled:
      userRole &&
      [
        "owner",
        "manager",
        "admin",
        "director",
        "supervisor",
        "hr",
        "consultant",
        "recruiter",
      ].includes(userRole),
  });

  const { data: inactiveUserCount } = useQuery({
    queryKey: ["inactiveUserCount"],
    queryFn: () => fetchCounts("users", where("status", "==", "inactive")),
    enabled:
      userRole &&
      [
        "owner",
        "manager",
        "admin",
        "director",
        "supervisor",
        "hr",
        "consultant",
        "recruiter",
      ].includes(userRole),
  });

  const { data: terminatedUserCount } = useQuery({
    queryKey: ["terminatedUserCount"],
    queryFn: () => fetchCounts("users", where("status", "==", "terminated")),
    enabled:
      userRole &&
      [
        "owner",
        "manager",
        "admin",
        "director",
        "supervisor",
        "hr",
        "consultant",
        "recruiter",
      ].includes(userRole),
  });

  const { data: locationsCount } = useQuery({
    queryKey: ["locationsCount"],
    queryFn: () => fetchCounts("locations"),
    enabled:
      userRole &&
      [
        "owner",
        "manager",
        "director",
        "admin",
        "consultant",
        "recruiter",
        "hr",
        "supervisor",
      ].includes(userRole),
  });

  useEffect(() => {
    const intervals = {};

    Object.keys(counterStates).forEach((key) => {
      if (counterStates[key].isLoading) {
        intervals[key] = setInterval(() => {
          setCounterStates((prev) => ({
            ...prev,
            [key]: {
              ...prev[key],
              value: prev[key].value + Math.floor(Math.random() * 5),
            },
          }));
        }, 100);
      }
    });

    return () => {
      Object.values(intervals).forEach(clearInterval);
    };
  }, [counterStates]);

  useEffect(() => {
    const counters = {
      applicationCount,
      activeUserCount,
      inactiveUserCount,
      terminatedUserCount,
      locationsCount,
      ticketCount,
    };

    Object.entries(counters).forEach(([key, value]) => {
      if (value !== undefined) {
        setTimeout(() => {
          setCounterStates((prev) => ({
            ...prev,
            [key]: { isLoading: false, value: value },
          }));
        }, Math.random() * 1000 + 500);
      }
    });
  }, [
    applicationCount,
    activeUserCount,
    inactiveUserCount,
    terminatedUserCount,
    locationsCount,
    ticketCount,
  ]);

  useRequireNCASignature();

  useEffect(() => {
    const fetchAndSetUserDetails = async () => {
      if (!auth.currentUser) {
        navigate("/login");
        return;
      }

      if (
        location.state &&
        location.state.firstName &&
        location.state.lastName
      ) {
        const fullName =
          `${location.state.firstName} ${location.state.lastName}`.trim();
        setUserFullName(fullName);
        setUserName(fullName);
        localStorage.setItem("userFirstName", location.state.firstName);
        localStorage.setItem("userLastName", location.state.lastName);
      } else if (!userFullName) {
        // Fallback to fetching from Firestore if names are not in local storage
        try {
          const currentUserUID = auth.currentUser.uid;
          const userRef = doc(db, "users", currentUserUID);
          const docSnapshot = await getDoc(userRef);

          if (docSnapshot.exists()) {
            const data = docSnapshot.data();
            const firstName = data.firstName || "";
            const lastName = data.lastName || "";
            const fullName = `${firstName} ${lastName}`.trim();

            setUserFullName(fullName);
            setUserName(fullName);
            localStorage.setItem("userFirstName", firstName);
            localStorage.setItem("userLastName", lastName);
          }
        } catch (error) {
          console.error("Failed to fetch user details:", error);
        }
      }
    };

    fetchAndSetUserDetails();
  }, [navigate, setUserName, location.state, userFullName]);

  const fetchCounts = async (collectionName, whereCondition = null) => {
    const ref = collection(db, collectionName);
    const q = whereCondition ? query(ref, whereCondition) : ref;
    const snapshot = await getDocs(q);
    return snapshot.size;
  };

  useEffect(() => {
    const currentHour = new Date().getHours();
    if (currentHour < 12) {
      setGreeting("Good morning");
    } else if (currentHour < 18) {
      setGreeting("Good afternoon");
    } else {
      setGreeting("Good evening");
    }
  }, []);

  const { isLoaded: isLoadedMaps, loadError } = useContext(GoogleMapsContext);
  if (!isLoadedMaps) {
    return <div>Loading Google Maps...</div>;
  }

  if (loadError) {
    return <div>Error loading Google Maps: {loadError.message}</div>;
  }

  return (
    <div className="flex min-h-screen bg-[#1F2937]">
      <div className="fixed top-0 left-0 w-full h-12 bg-gray-800 z-50 flex justify-between items-center px-3">
        <h1
          className="text-2xl font-bold text-white audiowide cursor-pointer"
          onClick={() => {
            window.location.reload();
          }}
        >
          UNIQUE HUB
        </h1>
        <div className="flex items-center">
          <span className="mr-4 text-white font-bold hidden sm:inline">
            {greeting}
            {userFullName ? `, ${userFullName}` : ""}
          </span>
          <div className="relative mr-4">
            <button
              onClick={() => setIsFlyoutOpen(!isFlyoutOpen)}
              className="p-2 bg-green-500 hover:bg-green-700 text-white font-bold rounded transition duration-150 ease-in-out"
            >
              <FontAwesomeIcon icon={faCakeCandles} />
              <span className="hidden sm:inline"> Birthdays</span>
            </button>
            {isFlyoutOpen && (
              <BirthdayFlyout closeFlyout={() => setIsFlyoutOpen(false)} />
            )}
          </div>
          <button
            onClick={() => auth.signOut()}
            className="p-2 bg-red-500 hover:bg-red-700 text-white font-bold rounded transition duration-150 ease-in-out"
          >
            <FontAwesomeIcon icon={faRightFromBracket} />
            <span className="hidden sm:inline"> Logout</span>
          </button>
        </div>
      </div>
      <button
        className="sm:hidden z-50 fixed top-14 left-4 text-white"
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
      >
        <FontAwesomeIcon icon={faBarsStaggered} />
      </button>
      <aside
        id="default-sidebar"
        className={`fixed top-20 sm:top-12 left-0 z-40 w-64 h-[calc(100vh-5rem)] sm:h-[calc(100vh-3rem)] transition-transform ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } sm:translate-x-0`}
        aria-label="Sidebar"
      >
        <div className="h-full px-3 overflow-y-auto bg-gray-50 dark:bg-gray-800">
          <ul className="space-y-2 font-medium">
            {userRole !== "supervisor" && (
              <li>
                <Link
                  to="/dashboard"
                  className="flex items-center justify-between p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                >
                  <div className="flex items-center">
                    <FontAwesomeIcon
                      icon={faUsers}
                      className="w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                    />
                    <span className="ml-3">Active</span>
                  </div>
                  <span className="inline-flex items-center px-3 py-1 text-sm font-medium text-blue-800 bg-blue-100 rounded-full dark:bg-blue-900 dark:text-blue-300">
                    <Odometer
                      value={counterStates.activeUserCount.value}
                      format="d"
                    />
                  </span>
                </Link>
              </li>
            )}
            {(userRole === "owner" ||
              userRole === "recruiter" ||
              userRole === "hr" ||
              userRole === "director") && (
              <li>
                <Link
                  to="/dashboard/inactive"
                  className="flex items-center justify-between p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                >
                  <div className="flex items-center">
                    <FontAwesomeIcon
                      icon={faUserClock}
                      className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                    />
                    <span className="ml-3">Inactive</span>
                  </div>
                  <span className="inline-flex items-center px-3 py-1 text-sm font-medium text-blue-800 bg-blue-100 rounded-full dark:bg-blue-900 dark:text-blue-300">
                    <Odometer
                      value={counterStates.inactiveUserCount.value}
                      format="d"
                    />
                  </span>
                </Link>
              </li>
            )}
            {(userRole === "owner" || userRole === "hr") && (
              <li>
                <Link
                  to="/dashboard/terminated"
                  className="flex items-center justify-between p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                >
                  <div className="flex items-center">
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                    />
                    <span className="ml-3">Terminated</span>
                  </div>
                  <span className="inline-flex items-center px-3 py-1 text-sm font-medium text-blue-800 bg-blue-100 rounded-full dark:bg-blue-900 dark:text-blue-300">
                    <Odometer
                      value={counterStates.terminatedUserCount.value}
                      format="d"
                    />
                  </span>
                </Link>
              </li>
            )}
            {(userRole === "owner" ||
              userRole === "hr" ||
              userRole === "manager" ||
              userRole === "consultant") && (
              <li>
                <Link
                  to="/dashboard/purgatory"
                  className="flex items-center justify-between p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                >
                  <div className="flex items-center">
                    <FontAwesomeIcon
                      icon={faFireAlt}
                      className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                    />
                    <span className="ml-3">Purgatory</span>
                  </div>
                </Link>
              </li>
            )}
            {(userRole === "owner" ||
              userRole === "recruiter" ||
              userRole === "hr" ||
              userRole === "director") && (
              <li>
                <Link
                  to="/dashboard/review"
                  className="flex items-center justify-between p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                >
                  <div className="flex items-center">
                    <FontAwesomeIcon
                      icon={faInbox}
                      className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                    />
                    <span className="ml-3">Applications</span>
                  </div>
                  <span className="inline-flex items-center px-3 py-1 text-sm font-medium text-blue-800 bg-blue-100 rounded-full dark:bg-blue-900 dark:text-blue-300">
                    <Odometer
                      value={counterStates.applicationCount.value}
                      format="d"
                    />
                  </span>
                </Link>
              </li>
            )}
            {userRole === "owner" && (
              <li>
                <Link
                  to="/dashboard/rejected"
                  className="flex items-center justify-between p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                >
                  <div className="flex items-center">
                    <FontAwesomeIcon
                      icon={faBan}
                      className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                    />
                    <span className="ml-3">Rejected</span>
                  </div>
                </Link>
              </li>
            )}
            {(userRole === "owner" ||
              userRole === "manager" ||
              userRole === "hr" ||
              userRole === "director" ||
              userRole === "consultant" ||
              userRole === "admin") && (
              <li>
                <Link
                  to="/dashboard/locations"
                  className="flex items-center justify-between p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                >
                  <div className="flex items-center">
                    <FontAwesomeIcon
                      icon={faLocationDot}
                      className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                    />
                    <span className="ml-3">Locations</span>
                  </div>
                  <span className="inline-flex items-center px-3 py-1 text-sm font-medium text-blue-800 bg-blue-100 rounded-full dark:bg-blue-900 dark:text-blue-300">
                    <Odometer
                      value={counterStates.locationsCount.value}
                      format="d"
                    />
                  </span>
                </Link>
              </li>
            )}
            {userRole === "hr" && (
              <li>
                <Link
                  to="/dashboard/concerns"
                  className="flex items-center justify-between p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                >
                  <div className="flex items-center">
                    <FontAwesomeIcon
                      icon={faHandshake}
                      className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                    />
                    <span className="ml-3">Concerns</span>
                  </div>
                </Link>
              </li>
            )}
            {userRole === "owner" && (
              <li>
                <Link
                  to="/dashboard/reports"
                  className="flex items-center justify-between p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                >
                  <div className="flex items-center">
                    <FontAwesomeIcon
                      icon={faFileInvoiceDollar}
                      className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                    />
                    <span className="ml-3">Reports</span>
                  </div>
                </Link>
              </li>
            )}
            {userRole === "owner" && (
              <li>
                <Link
                  to="/dashboard/salary"
                  className="flex items-center justify-between p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                >
                  <div className="flex items-center">
                    <FontAwesomeIcon
                      icon={faMoneyCheckDollar}
                      className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                    />
                    <span className="ml-3">Salary</span>
                  </div>
                </Link>
              </li>
            )}
            {(userRole === "owner" || userRole === "admin") && (
              <li>
                <Link
                  to="/dashboard/invoices"
                  className="flex items-center justify-between p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                >
                  <div className="flex items-center">
                    <FontAwesomeIcon
                      icon={faFileInvoice}
                      className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                    />
                    <span className="ml-3">Invoices</span>
                  </div>
                </Link>
              </li>
            )}
            {(userRole === "owner" ||
              userRole === "admin" ||
              userRole === "director" ||
              userRole === "hr" ||
              userRole === "consultant" ||
              userRole === "recruiter" ||
              userRole === "supervisor" ||
              userRole === "manager") && (
              <li>
                <Link
                  to="/dashboard/tickets"
                  className="flex items-center justify-between p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                >
                  <div className="flex items-center">
                    <FontAwesomeIcon
                      icon={faTicket}
                      className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                    />
                    <span className="ml-3">Tickets</span>
                  </div>
                  <span className="inline-flex items-center px-3 py-1 text-sm font-medium text-blue-800 bg-blue-100 rounded-full dark:bg-blue-900 dark:text-blue-300">
                    <Odometer
                      value={counterStates.ticketCount.value}
                      format="d"
                    />
                  </span>
                </Link>
              </li>
            )}
            {(userRole === "owner" ||
              userRole === "supervisor" ||
              userRole === "hr" ||
              userRole === "manager" ||
              userRole === "director" ||
              userRole === "consultant" ||
              userRole === "admin") && (
              <li>
                <Link
                  to="/dashboard/vincollect"
                  className="flex items-center justify-between p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                >
                  <div className="flex items-center">
                    <FontAwesomeIcon
                      icon={faSprayCanSparkles}
                      className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                    />
                    <span className="ml-3">Vin Collect</span>
                  </div>
                </Link>
              </li>
            )}
            {(userRole === "owner" ||
              userRole === "manager" ||
              userRole === "hr" ||
              userRole === "director" ||
              userRole === "consultant" ||
              userRole === "supervisor") && (
              <li>
                <Link
                  to="/dashboard/accidents"
                  className="flex items-center justify-between p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                >
                  <div className="flex items-center">
                    <FontAwesomeIcon
                      icon={faCarCrash}
                      className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                    />
                    <span className="ml-3">Accidents</span>
                  </div>
                </Link>
              </li>
            )}
            {(userRole === "owner" ||
              userRole === "supervisor" ||
              userRole === "hr" ||
              userRole === "director" ||
              userRole === "consultant" ||
              userRole === "manager" ||
              userRole === "admin") && (
              <li>
                <Link
                  to="/dashboard/timesheets"
                  className="flex items-center justify-between p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                >
                  <div className="flex items-center">
                    <FontAwesomeIcon
                      icon={faClock}
                      className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                    />
                    <span className="ml-3">Timesheets</span>
                  </div>
                </Link>
              </li>
            )}
            {(userRole === "owner" || userRole === "hr") && (
              <li>
                <Link
                  to="/dashboard/timeoff"
                  className="flex items-center justify-between p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                >
                  <div className="flex items-center">
                    <FontAwesomeIcon
                      icon={faCalendarDays}
                      className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                    />
                    <span className="ml-3">Time Off</span>
                  </div>
                </Link>
              </li>
            )}
            {(userRole === "owner" || userRole === "hr") && (
              <li>
                <Link
                  to="/dashboard/compendium"
                  className="flex items-center justify-between p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                >
                  <div className="flex items-center">
                    <FontAwesomeIcon
                      icon={faAddressBook}
                      className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                    />
                    <span className="ml-3">Compendium</span>
                  </div>
                </Link>
              </li>
            )}
            {(userRole === "owner" ||
              userRole === "supervisor" ||
              userRole === "consultant" ||
              userRole === "manager" ||
              userRole === "director") && (
              <li>
                <Link
                  to="/dashboard/transactions"
                  className="flex items-center justify-between p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                >
                  <div className="flex items-center">
                    <FontAwesomeIcon
                      icon={faRightLeft}
                      className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                    />
                    <span className="ml-3">Transactions</span>
                  </div>
                </Link>
              </li>
            )}
            {userRole === "owner" && (
              <li>
                <Link
                  to="/dashboard/statistics"
                  className="flex items-center justify-between p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                >
                  <div className="flex items-center">
                    <FontAwesomeIcon
                      icon={faChartLine}
                      className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                    />
                    <span className="ml-3">Statistics</span>
                  </div>
                </Link>
              </li>
            )}
          </ul>
        </div>
      </aside>
      <div className="flex-grow p-4 sm:ml-64 w-full mt-12">
        <div className="flex flex-col h-full bg-[#1F2937]">
          <div className="flex justify-between items-center mb-4"></div>
          <div className="flex-grow">
            <Routes>
              <Route
                path="/"
                element={
                  <UserDirectory
                    key="active"
                    userStatus="Active"
                    userRole={userRole}
                  />
                }
              />
              <Route
                path="inactive"
                element={
                  <UserDirectory
                    key="inactive"
                    userStatus="inactive"
                    userRole={userRole}
                  />
                }
              />
              <Route
                path="terminated"
                element={
                  <UserDirectory
                    key="terminated"
                    userStatus="terminated"
                    userRole={userRole}
                  />
                }
              />
              <Route path="review" element={<ApplicationReview />} />
              <Route path="rejected" element={<Rejected />} />
              <Route
                path="locations"
                element={
                  <>
                    <Locations userRole={userRole} />
                  </>
                }
              />
              <Route path="reports" element={<Reports />} />
              <Route path="salary" element={<Salary />} />
              <Route path="invoices" element={<Invoices />} />
              <Route
                path="accidents"
                element={<Accidents userRole={userRole} />}
              />
              <Route path="purgatory" element={<Purgatory />} />
              <Route path="tickets" element={<Tickets />} />
              <Route
                path="vincollect"
                element={<VinCollect userRole={userRole} />}
              />
              <Route path="concerns" element={<Concerns />} />
              <Route path="timesheets" element={<Timesheets />} />
              <Route path="timeoff" element={<TimeOff />} />
              <Route path="compendium" element={<Compendium />} />
              <Route path="transactions" element={<Transactions />} />
              <Route path="statistics" element={<Statistics />} />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
