import React, { useState, useMemo, useEffect } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  flexRender,
} from "@tanstack/react-table";
import { auth, db, storage } from "./firebase";
import {
  collection,
  getDocs,
  onSnapshot,
  doc,
  updateDoc,
  Timestamp,
  arrayUnion,
  getDoc,
} from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faMapMarkerAlt,
  faUser,
  faUserTie,
  faExclamationTriangle,
  faLongArrowAltLeft,
  faLongArrowAltRight,
  faBuilding,
  faFilePdf,
  faFileAlt,
  faTimes,
  faHandshake,
} from "@fortawesome/free-solid-svg-icons";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";

// Fetch concerns from Firestore
const fetchConcerns = async () => {
  const concernsCollection = collection(db, "concerns");
  const concernsSnapshot = await getDocs(concernsCollection);
  return concernsSnapshot.docs.map((doc) => {
    const data = doc.data();
    // Convert any existing concerns to new format if needed
    return {
      id: doc.id,
      ...data,
      status: data.status || "pending",
      createdBy: data.createdBy || {
        uid: data.userUid || '',
        name: data.userName || ''
      },
      personInvolved: data.personInvolved || {
        uid: data.personInvolvedUid || '',
        name: data.personInvolvedName || ''
      },
      publicComments: data.publicComments || [],
      hrNotes: data.hrNotes || data.hrComments || [], // Migration support
      responses: data.responses || [],
      lastUpdatedBy: data.lastUpdatedBy ? {
        uid: data.lastUpdatedByUid || '',
        name: data.lastUpdatedBy
      } : null
    };
  });
};

// Subscribe to real-time updates
const subscribeToConcerns = (queryClient) => {
  const concernsCollection = collection(db, "concerns");
  return onSnapshot(concernsCollection, (snapshot) => {
    const concerns = snapshot.docs.map((doc) => {
      const data = doc.data();
      return {
        id: doc.id,
        ...data,
        status: data.status || "pending",
        // Add other default fields as needed
      };
    });
    queryClient.setQueryData(["concerns"], concerns);
  });
};

function ConcernSummary({ concerns, filterStatus, onFilterChange }) {
  const summary = useMemo(() => {
    return concerns?.reduce(
      (acc, concern) => {
        acc.total++;
        acc[concern.status.toLowerCase()]++;
        return acc;
      },
      { total: 0, pending: 0, investigating: 0, resolved: 0, closed: 0 }
    );
  }, [concerns]);

  const getButtonStyles = (status) => {
    const baseStyles = "rounded-lg p-6 shadow-lg transform hover:scale-105 transition-transform duration-200 cursor-pointer";
    const isActive = filterStatus === status;
    
    if (status === 'total') {
      return `${baseStyles} ${isActive ? 'bg-gray-600' : 'bg-gray-700'} hover:bg-gray-600`;
    }
    
    const statusStyles = {
      pending: isActive ? 'bg-yellow-700' : 'bg-yellow-600',
      investigating: isActive ? 'bg-blue-700' : 'bg-blue-600',
      resolved: isActive ? 'bg-green-700' : 'bg-green-600',
      closed: isActive ? 'bg-purple-700' : 'bg-purple-600'
    };

    return `${baseStyles} ${statusStyles[status] || 'bg-gray-700'}`;
  };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4">
      <div
        onClick={() => onFilterChange(null)}
        className={getButtonStyles('total')}
      >
        <div className="text-gray-300 font-medium mb-2">Total Concerns</div>
        <div className="text-3xl font-bold text-white">{summary?.total || 0}</div>
      </div>
      <div
        onClick={() => onFilterChange("pending")}
        className={getButtonStyles('pending')}
      >
        <div className="text-yellow-100 font-medium mb-2">Pending</div>
        <div className="text-3xl font-bold text-white">{summary?.pending || 0}</div>
      </div>
      <div
        onClick={() => onFilterChange("investigating")}
        className={getButtonStyles('investigating')}
      >
        <div className="text-blue-100 font-medium mb-2">Investigating</div>
        <div className="text-3xl font-bold text-white">{summary?.investigating || 0}</div>
      </div>
      <div
        onClick={() => onFilterChange("resolved")}
        className={getButtonStyles('resolved')}
      >
        <div className="text-green-100 font-medium mb-2">Resolved</div>
        <div className="text-3xl font-bold text-white">{summary?.resolved || 0}</div>
      </div>
      <div
        onClick={() => onFilterChange("closed")}
        className={getButtonStyles('closed')}
      >
        <div className="text-purple-100 font-medium mb-2">Closed</div>
        <div className="text-3xl font-bold text-white">{summary?.closed || 0}</div>
      </div>
    </div>
  );
}

const isImageFile = (url) => {
  try {
    const fileNameMatch = url.match(/\/([^/?#]+)[^/]*$/);
    const fileName = fileNameMatch ? fileNameMatch[1] : "";
    const hasImageExtension = /\.(jpg|jpeg|png|gif|webp)$/i.test(fileName);
    return hasImageExtension;
  } catch (error) {
    console.error("Error parsing URL:", error);
    return false;
  }
};

const isPdfFile = (url) => {
  try {
    const fileNameMatch = url.match(/\/([^/?#]+)[^/]*$/);
    const fileName = fileNameMatch ? fileNameMatch[1] : "";
    return /\.pdf$/i.test(fileName);
  } catch (error) {
    console.error("Error parsing URL:", error);
    return false;
  }
};

function ConcernDetails({ concern, onClose, userName, isHR }) {
  const [expandedImage, setExpandedImage] = useState(null);
  const [newComment, setNewComment] = useState("");
  const [isHrComment, setIsHrComment] = useState(false);
  const [isAddingComment, setIsAddingComment] = useState(false);
  const [status, setStatus] = useState(concern?.status || "pending");
  const [currentConcern, setCurrentConcern] = useState(concern);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isRequestingResponse, setIsRequestingResponse] = useState(false);
  const [responseTarget, setResponseTarget] = useState(null);

  useEffect(() => {
    // Set up real-time listener for concern updates
    const unsubscribe = onSnapshot(
      doc(db, "concerns", concern.id),
      (docSnapshot) => {
        const updatedData = docSnapshot.data();
        setStatus(updatedData.status || "pending");
        setCurrentConcern({ id: docSnapshot.id, ...updatedData });
      }
    );

    return () => unsubscribe();
  }, [concern.id]);

  useEffect(() => {
    console.log('Response Target:', responseTarget);
    console.log('Is Requesting Response:', isRequestingResponse);
  }, [responseTarget, isRequestingResponse]);

  const handleAddComment = async () => {
    if (!newComment.trim()) return;

    setIsAddingComment(true);
    try {
      const concernRef = doc(db, "concerns", concern.id);
      const comment = {
        id: crypto.randomUUID(), // Generate unique ID
        text: newComment,
        createdAt: Timestamp.now(),
        createdBy: {
          uid: auth.currentUser?.uid || localStorage.getItem("userUid"),
          name: userName
        }
      };

      // Update the appropriate comments array based on type
      const field = isHrComment ? "hrNotes" : "publicComments";
      
      await updateDoc(concernRef, {
        [field]: arrayUnion(comment),
        lastUpdatedBy: {
          uid: auth.currentUser?.uid || localStorage.getItem("userUid"),
          name: userName
        },
        lastUpdatedAt: Timestamp.now(),
      });

      setNewComment("");
    } catch (error) {
      console.error("Error adding comment:", error);
    } finally {
      setIsAddingComment(false);
    }
  };

  const handleFieldUpdate = async (field, value) => {
    try {
      const concernRef = doc(db, "concerns", concern.id);
      await updateDoc(concernRef, {
        [field]: value,
        lastUpdatedBy: userName,
        lastUpdatedAt: Timestamp.now(),
      });
    } catch (error) {
      console.error(`Error updating ${field}:`, error);
    }
  };

  const formatDateTime = (timestamp) => {
    const date = new Date(timestamp.seconds * 1000);
    return {
      date: date.toLocaleDateString(),
      time: date.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      }),
    };
  };

  const { date } = formatDateTime(concern.dateSubmitted);

  const handleFileUpload = async (event) => {
    const files = Array.from(event.target.files);
    if (files.length === 0) return;

    setIsUploading(true);
    setUploadProgress(0);

    try {
      const uploadPromises = files.map(async (file) => {
        // Create a storage reference
        const storageRef = ref(
          storage,
          `concerns/${concern.id}/${Date.now()}-${file.name}`
        );

        // Upload file with progress monitoring
        const uploadTask = uploadBytes(storageRef, file);

        // Wait for upload to complete
        await uploadTask;

        // Get download URL
        const downloadURL = await getDownloadURL(storageRef);
        return downloadURL;
      });

      const uploadedUrls = await Promise.all(uploadPromises);

      // Update concern with new evidence
      const concernRef = doc(db, "concerns", concern.id);
      await updateDoc(concernRef, {
        evidenceUrls: arrayUnion(...uploadedUrls),
        lastUpdatedBy: userName,
        lastUpdatedAt: Timestamp.now(),
      });

      // Add a system comment about the evidence
      const comment = {
        text: `Added ${files.length} evidence file${files.length > 1 ? "s" : ""}`,
        createdBy: userName,
        createdAt: Timestamp.now(),
        isSystemComment: true,
      };

      await updateDoc(concernRef, {
        comments: arrayUnion(comment),
      });
    } catch (error) {
      console.error("Error uploading files:", error);
    } finally {
      setIsUploading(false);
      setUploadProgress(0);
    }
  };

  const handleRemoveEvidence = async (urlToRemove) => {
    try {
      // Create a reference to the file to delete
      const fileRef = ref(storage, urlToRemove);
      await deleteObject(fileRef);

      // Update the concern document to remove the URL
      const concernRef = doc(db, "concerns", concern.id);
      const updatedEvidence = currentConcern.evidenceUrls.filter(
        (url) => url !== urlToRemove
      );

      await updateDoc(concernRef, {
        evidenceUrls: updatedEvidence,
        lastUpdatedBy: userName,
        lastUpdatedAt: Timestamp.now(),
      });

      // Add a system comment about removing the evidence
      const comment = {
        text: "Removed an evidence file",
        createdBy: userName,
        createdAt: Timestamp.now(),
        isSystemComment: true,
      };

      await updateDoc(concernRef, {
        comments: arrayUnion(comment),
      });
    } catch (error) {
      console.error("Error removing evidence:", error);
    }
  };

  const handleRequestResponse = async () => {
    if (!newComment.trim()) return;

    setIsAddingComment(true);
    try {
      const concernRef = doc(db, "concerns", concern.id);
      
      const responseRequest = {
        id: crypto.randomUUID(),
        question: newComment,
        requestedAt: Timestamp.now(),
        requestedBy: {
          uid: auth.currentUser?.uid || localStorage.getItem("userUid"),
          name: userName
        },
        requestedFrom: {
          uid: responseTarget.uid || concern.userUid,
          name: responseTarget.name
        },
        response: null,
        status: 'pending'
      };

      await updateDoc(concernRef, {
        responses: arrayUnion(responseRequest),
        lastUpdatedBy: {
          uid: auth.currentUser?.uid || localStorage.getItem("userUid"),
          name: userName
        },
        lastUpdatedAt: Timestamp.now(),
      });

      setNewComment("");
      setIsRequestingResponse(false);
      setResponseTarget(null);
    } catch (error) {
      console.error("Error requesting response:", error);
    } finally {
      setIsAddingComment(false);
    }
  };

  return (
    <div
      className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50"
      onClick={onClose}
    >
      <div
        className="relative top-20 mx-auto p-8 border w-11/12 md:w-3/4 lg:w-2/3 shadow-lg rounded-lg bg-white"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-between items-center mb-6">
          <div>
            <h2 className="text-3xl font-bold text-gray-900 flex items-center">
              <FontAwesomeIcon
                icon={faHandshake}
                className="text-red-500 mr-3"
              />
              HR Concern
            </h2>
            <p className="text-sm text-gray-500 mt-1">Concern Details</p>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="bg-gray-50 p-6 rounded-lg shadow">
            <h3 className="text-xl font-semibold text-gray-800 mb-4">
              General Information
            </h3>
            <div className="space-y-4">
              <div className="flex items-center">
                <FontAwesomeIcon icon={faCalendarAlt} className="text-gray-500 mr-2" />
                <span className="text-gray-700">{date}</span>
              </div>
              <div className="flex items-center">
                <FontAwesomeIcon icon={faMapMarkerAlt} className="text-gray-500 mr-2" />
                <span className="text-gray-700">{concern.location}</span>
              </div>
              <div className="flex items-center">
                <FontAwesomeIcon icon={faUser} className="text-gray-500 mr-2" />
                <span className="text-gray-700">{concern.createdBy?.name || concern.userName}</span>
              </div>
              {(concern.personInvolved?.name || concern.personInvolvedName) && (
                <div className="flex items-center">
                  <FontAwesomeIcon icon={faUserTie} className="text-gray-500 mr-2" />
                  <span className="text-gray-700">
                    Person Involved: {concern.personInvolved?.name || concern.personInvolvedName}
                  </span>
                </div>
              )}

              {isHR && (
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Status:
                  </label>
                  <select
                    value={status}
                    onChange={(e) => {
                      setStatus(e.target.value);
                      handleFieldUpdate("status", e.target.value);
                    }}
                    className="shadow border rounded w-full py-2 px-3 text-gray-700"
                  >
                    <option value="pending">Pending</option>
                    <option value="investigating">Investigating</option>
                    <option value="resolved">Resolved</option>
                    <option value="closed">Closed</option>
                  </select>
                </div>
              )}

              {concern.lastUpdatedBy && (
                <div className="mt-4 text-sm text-gray-600">
                  <p>Last updated by: {concern.lastUpdatedBy?.name || concern.lastUpdatedBy}</p>
                  <p>
                    {concern.lastUpdatedAt?.toDate().toLocaleString() || "Unknown time"}
                  </p>
                </div>
              )}
            </div>
          </div>

          <div className="bg-gray-50 p-6 rounded-lg shadow">
            <h3 className="text-xl font-semibold text-gray-800 mb-4">
              Description
            </h3>
            <p className="text-gray-800 whitespace-pre-wrap">
              {concern.description || "No description provided"}
            </p>
          </div>

          {/* Evidence/Attachments Section */}
          <div className="col-span-2 bg-gray-50 p-6 rounded-lg shadow">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-semibold text-gray-800">Evidence Files</h3>
              <input
                type="file"
                id="file-upload"
                multiple
                onChange={handleFileUpload}
                className="hidden"
              />
              <label
                htmlFor="file-upload"
                className="cursor-pointer bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-lg transition-colors duration-200"
              >
                Add Files
              </label>
            </div>

            {isUploading && (
              <div className="mb-4">
                <div className="w-full bg-gray-200 rounded-full h-2.5">
                  <div
                    className="bg-blue-600 h-2.5 rounded-full transition-all duration-300"
                    style={{ width: `${uploadProgress}%` }}
                  ></div>
                </div>
                <p className="text-sm text-gray-600 mt-1">Uploading...</p>
              </div>
            )}

            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
              {currentConcern.evidenceUrls?.map((url, index) => {
                const isImage = isImageFile(url);
                const isPdf = isPdfFile(url);

                return (
                  <div key={index} className="relative group">
                    {isImage ? (
                      <img
                        src={url}
                        alt={`Evidence ${index + 1}`}
                        className="w-full h-32 object-cover rounded-md cursor-pointer hover:opacity-80 transition-opacity shadow"
                        onClick={() => setExpandedImage(url)}
                      />
                    ) : (
                      <a
                        href={url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center justify-center w-full h-32 bg-gray-100 rounded-md cursor-pointer hover:bg-gray-200 transition-colors shadow"
                      >
                        <div className="text-center">
                          <FontAwesomeIcon
                            icon={isPdf ? faFilePdf : faFileAlt}
                            className={`text-4xl mb-2 ${
                              isPdf ? "text-red-600" : "text-gray-600"
                            }`}
                          />
                          <p className="text-sm text-gray-600">
                            {isPdf ? "View PDF" : "View File"}
                          </p>
                        </div>
                      </a>
                    )}

                    {(isHR || concern.userUid === (auth.currentUser?.uid || localStorage.getItem("userUid"))) && (
                      <button
                        onClick={() => handleRemoveEvidence(url)}
                        className="absolute top-1 right-1 bg-red-500 text-white rounded-full p-1 opacity-0 group-hover:opacity-100 transition-opacity"
                      >
                        <FontAwesomeIcon icon={faTimes} className="w-4 h-4" />
                      </button>
                    )}
                  </div>
                );
              })}
            </div>
          </div>

          {/* Comments and Response Requests Section */}
          <div className="col-span-2 space-y-6">
            {/* Public Comments Section */}
            <div className="bg-gray-50 p-6 rounded-lg shadow">
              <h3 className="text-xl font-semibold text-gray-800 mb-4">
                Shared Comments
                <span className="ml-2 text-sm text-blue-600 font-normal">
                  (Visible to all parties)
                </span>
              </h3>
              <div className="space-y-4 mb-4 max-h-60 overflow-y-auto">
                {currentConcern.publicComments?.length > 0 ? (
                  currentConcern.publicComments?.map((comment) => (
                    <div
                      key={comment.id}
                      className="bg-white p-4 rounded-lg shadow border-l-4 border-blue-500"
                    >
                      <div className="flex justify-between items-start">
                        <span className="font-semibold text-gray-700">
                          {comment.createdBy.name}
                        </span>
                        <span className="text-sm text-gray-500">
                          {comment.createdAt?.toDate().toLocaleString()}
                        </span>
                      </div>
                      <p className="mt-2 text-gray-600">{comment.text}</p>
                    </div>
                  ))
                ) : (
                  <p className="text-gray-500 text-center italic">
                    No shared comments yet
                  </p>
                )}
              </div>
            </div>

            {/* Responses Section */}
            {isHR && (
              <div className="bg-purple-50 p-6 rounded-lg shadow">
                <h3 className="text-xl font-semibold text-gray-800 mb-4">
                  Response Requests
                </h3>
                <div className="grid grid-cols-2 gap-4">
                  {/* Creator's Responses Column */}
                  <div className="space-y-4">
                    <h4 className="font-semibold text-blue-600 border-b border-blue-200 pb-2">
                      Responses from {concern.createdBy?.name || 'Unknown User'}
                    </h4>
                    {currentConcern.responses
                      ?.filter(response => response.requestedFrom?.uid === concern.createdBy?.uid)
                      .map((response) => (
                        <div
                          key={response.id}
                          className="bg-white p-4 rounded-lg shadow border-l-4 border-blue-500"
                        >
                          <div className="flex justify-between items-start">
                            <span className="font-semibold text-gray-700">
                              Question from HR
                            </span>
                            <span className="text-sm text-gray-500">
                              {response.requestedAt?.toDate().toLocaleString()}
                            </span>
                          </div>
                          <p className="mt-2 text-gray-600 font-medium">{response.question}</p>
                          
                          {response.response && (
                            <div className="mt-4 pl-4 border-l-2 border-blue-200 bg-blue-50 p-3 rounded">
                              <div className="flex justify-between items-start">
                                <span className="font-semibold text-blue-700">
                                  Response from {response.response.respondedBy.name}
                                </span>
                                <span className="text-sm text-gray-500">
                                  {response.response.respondedAt?.toDate().toLocaleString()}
                                </span>
                              </div>
                              <p className="mt-2 text-gray-600">{response.response.text}</p>
                            </div>
                          )}
                        </div>
                      ))}
                  </div>

                  {/* Person Involved's Responses Column */}
                  {concern.personInvolved?.name && (
                    <div className="space-y-4">
                      <h4 className="font-semibold text-purple-600 border-b border-purple-200 pb-2">
                        Responses from {concern.personInvolved.name}
                      </h4>
                      {currentConcern.responses
                        ?.filter(response => response.requestedFrom?.uid === concern.personInvolved?.uid)
                        .map((response) => (
                          <div
                            key={response.id}
                            className="bg-white p-4 rounded-lg shadow border-l-4 border-purple-500"
                          >
                            <div className="flex justify-between items-start">
                              <span className="font-semibold text-gray-700">
                                Question from HR
                              </span>
                              <span className="text-sm text-gray-500">
                                {response.requestedAt?.toDate().toLocaleString()}
                              </span>
                            </div>
                            <p className="mt-2 text-gray-600 font-medium">{response.question}</p>
                            
                            {response.response && (
                              <div className="mt-4 pl-4 border-l-2 border-purple-200 bg-purple-50 p-3 rounded">
                                <div className="flex justify-between items-start">
                                  <span className="font-semibold text-purple-700">
                                    Response from {response.response.respondedBy.name}
                                  </span>
                                  <span className="text-sm text-gray-500">
                                    {response.response.respondedAt?.toDate().toLocaleString()}
                                  </span>
                                </div>
                                <p className="mt-2 text-gray-600">{response.response.text}</p>
                              </div>
                            )}
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              </div>
            )}

            {/* HR Notes Section - Only visible to admins */}
            {isHR && (
              <div className="bg-red-50 p-6 rounded-lg shadow">
                <h3 className="text-xl font-semibold text-gray-800 mb-4">
                  HR Notes
                  <span className="ml-2 text-sm text-red-600 font-normal">
                    (Only visible to HR)
                  </span>
                </h3>
                <div className="space-y-4 mb-4 max-h-60 overflow-y-auto">
                  {currentConcern.hrNotes?.length > 0 ? (
                    currentConcern.hrNotes?.map((note) => (
                      <div
                        key={note.id}
                        className="bg-white p-4 rounded-lg shadow border-l-4 border-red-500"
                      >
                        <div className="flex justify-between items-start">
                          <span className="font-semibold text-gray-700">
                            {note.createdBy.name}
                          </span>
                          <span className="text-sm text-gray-500">
                            {note.createdAt?.toDate().toLocaleString()}
                          </span>
                        </div>
                        <p className="mt-2 text-gray-600">{note.text}</p>
                      </div>
                    ))
                  ) : (
                    <p className="text-gray-500 text-center italic">
                      No HR notes yet
                    </p>
                  )}
                </div>
              </div>
            )}

            {/* Add Comment Form - Updated to include HR Notes */}
            <div className="bg-white p-6 rounded-lg shadow">
              <div className="flex items-center gap-4 mb-4">
                <button
                  onClick={() => {
                    setIsHrComment(false);
                    setIsRequestingResponse(false);
                  }}
                  className={`px-4 py-2 rounded-lg transition-colors ${
                    !isHrComment && !isRequestingResponse
                      ? 'bg-blue-500 text-white' 
                      : 'bg-gray-200 text-gray-700'
                  }`}
                >
                  Add Shared Comment
                </button>
                {isHR && (
                  <button
                    onClick={() => {
                      setIsHrComment(true);
                      setIsRequestingResponse(false);
                    }}
                    className={`px-4 py-2 rounded-lg transition-colors ${
                      isHrComment && !isRequestingResponse
                        ? 'bg-red-500 text-white' 
                        : 'bg-gray-200 text-gray-700'
                    }`}
                  >
                    Add HR Note
                  </button>
                )}
                {isHR && (
                  <>
                    <button
                      onClick={() => {
                        const creatorTarget = {
                          uid: concern.createdBy.uid,
                          name: concern.createdBy.name
                        };
                        setIsRequestingResponse(true);
                        setResponseTarget(creatorTarget);
                        setIsHrComment(false);
                      }}
                      className={`px-4 py-2 rounded-lg transition-colors ${
                        isRequestingResponse && responseTarget?.uid === concern.createdBy.uid
                          ? 'bg-blue-600 text-white' 
                          : 'bg-gray-200 text-gray-700'
                      }`}
                    >
                      Request Response from {concern.createdBy.name}
                    </button>
                    {concern.personInvolved?.name && (
                      <button
                        onClick={() => {
                          const involvedTarget = {
                            uid: concern.personInvolved.uid,
                            name: concern.personInvolved.name
                          };
                          setIsRequestingResponse(true);
                          setResponseTarget(involvedTarget);
                          setIsHrComment(false);
                        }}
                        className={`px-4 py-2 rounded-lg transition-colors ${
                          isRequestingResponse && responseTarget?.uid === concern.personInvolved.uid
                            ? 'bg-purple-600 text-white' 
                            : 'bg-gray-200 text-gray-700'
                        }`}
                      >
                        Request Response from {concern.personInvolved.name}
                      </button>
                    )}
                  </>
                )}
              </div>
              
              <textarea
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
                placeholder={
                  isRequestingResponse 
                    ? `Write your question for ${responseTarget?.name}...`
                    : isHrComment 
                      ? "Add a private HR note (only visible to HR)..." 
                      : "Add a shared comment (visible to all parties)..."
                }
                className={`w-full p-3 border rounded-lg focus:ring-2 focus:border-transparent ${
                  isHrComment 
                    ? 'border-red-300 focus:ring-red-500 bg-red-50' 
                    : 'border-gray-300 focus:ring-blue-500'
                }`}
                rows="3"
              />
              <button
                onClick={isRequestingResponse ? handleRequestResponse : handleAddComment}
                disabled={isAddingComment || !newComment.trim()}
                className={`mt-2 px-4 py-2 text-white rounded-lg transition-colors ${
                  isAddingComment 
                    ? 'opacity-50 cursor-not-allowed' 
                    : isRequestingResponse
                      ? responseTarget?.uid === concern.createdBy.uid
                        ? 'bg-blue-600 hover:bg-blue-700'
                        : 'bg-purple-600 hover:bg-purple-700'
                      : isHrComment 
                        ? 'bg-red-500 hover:bg-red-600' 
                        : 'bg-blue-500 hover:bg-blue-600'
                }`}
              >
                {isAddingComment 
                  ? "Adding..." 
                  : isRequestingResponse
                    ? `Request Response from ${responseTarget?.name}`
                    : isHrComment 
                      ? "Add HR Note" 
                      : "Add Shared Comment"
                }
              </button>
            </div>
          </div>
        </div>

        {expandedImage && (
          <div
            className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
            onClick={() => setExpandedImage(null)}
          >
            <img
              src={expandedImage}
              alt="Expanded view"
              className="max-w-full max-h-full p-4"
            />
          </div>
        )}
      </div>
    </div>
  );
}

function Concerns() {
  const [sorting, setSorting] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [statusFilter, setStatusFilter] = useState(null);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 50,
  });
  const [selectedConcern, setSelectedConcern] = useState(null);
  const [isHR, setIsHR] = useState(false);
  const [userName] = useState(() => {
    const firstName = localStorage.getItem("userFirstName") || "";
    const lastName = localStorage.getItem("userLastName") || "";
    return `${firstName} ${lastName}`.trim();
  });

  const queryClient = useQueryClient();

  // Add useEffect to check user role
  useEffect(() => {
    const checkUserRole = async () => {
      const currentUserUid = auth.currentUser?.uid || localStorage.getItem("userUid");
      if (!currentUserUid) return;

      try {
        const userDoc = await getDoc(doc(db, "users", currentUserUid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setIsHR(userData.role === "HR");
        }
      } catch (error) {
        console.error("Error checking user role:", error);
      }
    };

    checkUserRole();
  }, []); // Empty dependency array means this runs once on component mount

  const { data: concerns, isLoading, error } = useQuery({
    queryKey: ["concerns"],
    queryFn: () => {
      return fetchConcerns().then((initialData) => {
        subscribeToConcerns(queryClient);
        return initialData;
      });
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const filteredConcerns = useMemo(() => {
    if (!concerns) return [];

    let filtered = concerns.filter((concern) => {
      if (statusFilter && concern.status.toLowerCase() !== statusFilter) {
        return false;
      }

      if (searchText === "") return true;

      const searchLower = searchText.toLowerCase();
      return (
        concern.category?.toLowerCase().includes(searchLower) ||
        concern.description?.toLowerCase().includes(searchLower) ||
        concern.location?.toLowerCase().includes(searchLower) ||
        concern.userName?.toLowerCase().includes(searchLower) ||
        concern.status?.toLowerCase().includes(searchLower)
      );
    });

    return filtered;
  }, [concerns, searchText, statusFilter]);

  const columns = useMemo(
    () => [
      {
        header: "Status",
        accessorKey: "status",
        cell: ({ getValue }) => {
          const status = getValue()?.toLowerCase();
          const statusConfig = {
            pending: "bg-yellow-100 text-yellow-800",
            investigating: "bg-blue-100 text-blue-800",
            resolved: "bg-green-100 text-green-800",
            closed: "bg-purple-100 text-purple-800",
          };
          const bgColorClass = statusConfig[status] || "bg-gray-100 text-gray-800";
          return (
            <span className={`px-2 py-1 rounded-full text-xs font-semibold uppercase ${bgColorClass}`}>
              {status}
            </span>
          );
        },
      },
      {
        header: "Category",
        accessorKey: "category",
        cell: ({ getValue }) => (
          <span className="px-3 py-1 rounded-full text-xs font-semibold bg-gray-100 text-gray-800 flex items-center gap-1 w-fit">
            <FontAwesomeIcon icon={faExclamationTriangle} className="text-xs" />
            <span>{getValue()}</span>
          </span>
        ),
      },
      {
        header: "Location",
        accessorKey: "location",
        cell: ({ getValue }) => (
          <div className="flex items-center">
            <FontAwesomeIcon icon={faBuilding} className="text-gray-500 mr-2" />
            <span className="font-medium text-gray-700">{getValue()}</span>
          </div>
        ),
      },
      {
        header: "Submitted By",
        accessorFn: (row) => row.createdBy?.name || row.userName,
        cell: ({ getValue }) => (
          <div className="flex items-center">
            <div className="w-8 h-8 bg-blue-500 rounded-full flex items-center justify-center text-white font-semibold mr-2">
              {getValue()?.charAt(0)?.toUpperCase() || "?"}
            </div>
            <span className="font-medium text-gray-700">{getValue()}</span>
          </div>
        ),
      },
      {
        header: "Date Submitted",
        accessorKey: "dateSubmitted",
        cell: ({ getValue }) => {
          const date = getValue()?.toDate();
          if (!date) return "N/A";

          return (
            <div className="flex flex-col">
              <span className="font-medium text-gray-700">
                {date.toLocaleDateString()}
              </span>
              <span className="text-xs text-gray-500">
                {date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
              </span>
            </div>
          );
        },
      },
      {
        header: "Description",
        accessorKey: "description",
        cell: ({ getValue }) => (
          <span className="font-medium text-gray-900 line-clamp-2">
            {getValue() || "No description provided"}
          </span>
        ),
      },
      {
        header: "Actions",
        cell: ({ row }) => (
          <button
            onClick={() => handleViewDetails(row.original)}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded"
          >
            View Details
          </button>
        ),
      },
    ],
    []
  );

  const table = useReactTable({
    data: filteredConcerns,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      sorting,
      pagination,
    },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
  });

  const handleViewDetails = (concern) => {
    setSelectedConcern(concern);
  };

  if (isLoading) {
    return (
      <div className="container mx-auto px-4 sm:px-8 py-8">
        {/* Summary Cards Skeleton */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4 mb-6">
          {[...Array(5)].map((_, i) => (
            <div key={i} className="rounded-lg p-6 shadow-lg bg-gray-800">
              <Skeleton height={20} width={100} className="mb-2" />
              <Skeleton height={36} width={60} />
            </div>
          ))}
        </div>

        {/* Search Bar Skeleton */}
        <div className="my-6 p-6 bg-gray-800 rounded-lg shadow-lg">
          <Skeleton height={40} className="rounded-lg" />
        </div>

        {/* Table Skeleton */}
        <div className="bg-white rounded-lg shadow overflow-hidden">
          {/* Table Header */}
          <div className="grid grid-cols-7 bg-gray-100 border-b-2 border-gray-200">
            {[...Array(7)].map((_, i) => (
              <div key={i} className="px-5 py-3">
                <Skeleton height={16} width={100} />
              </div>
            ))}
          </div>

          {/* Table Rows */}
          {[...Array(5)].map((_, rowIndex) => (
            <div key={rowIndex} className="grid grid-cols-7 border-b border-gray-200">
              {[...Array(7)].map((_, colIndex) => (
                <div key={colIndex} className="px-5 py-5">
                  {colIndex === 0 && ( // Status column
                    <Skeleton height={20} width={80} className="rounded-full" />
                  )}
                  {colIndex === 1 && ( // Category column
                    <Skeleton height={20} width={100} className="rounded-full" />
                  )}
                  {colIndex === 2 && ( // Location column
                    <div className="flex items-center">
                      <Skeleton circle height={16} width={16} className="mr-2" />
                      <Skeleton height={20} width={120} />
                    </div>
                  )}
                  {colIndex === 3 && ( // Submitted By column
                    <div className="flex items-center">
                      <Skeleton circle height={32} width={32} className="mr-2" />
                      <Skeleton height={20} width={100} />
                    </div>
                  )}
                  {colIndex === 4 && ( // Date column
                    <div>
                      <Skeleton height={16} width={80} className="mb-1" />
                      <Skeleton height={12} width={60} />
                    </div>
                  )}
                  {colIndex === 5 && ( // Description column
                    <Skeleton height={20} width={200} />
                  )}
                  {colIndex === 6 && ( // Actions column
                    <Skeleton height={32} width={80} className="rounded" />
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>

        {/* Pagination Skeleton */}
        <div className="px-5 py-5 bg-gray-800 border-t border-gray-700 flex justify-between items-center mt-4">
          <div className="flex gap-2">
            <Skeleton height={36} width={100} className="rounded-full" />
            <Skeleton height={36} width={100} className="rounded-full" />
          </div>
          <Skeleton height={20} width={150} />
        </div>
      </div>
    );
  }

  if (error) {
    return <div className="text-red-500 text-center">Error: {error.message}</div>;
  }

  return (
    <div className="container mx-auto px-4 sm:px-8">
      <div className="py-8">
        <div className="flex justify-between items-center">
          <h1 className="text-lg font-bold text-white uppercase tracking-wider">
            HR Concerns
          </h1>
        </div>

        <div className="my-6 p-6 bg-gray-800 rounded-lg shadow-lg">
          <div className="flex flex-col space-y-6">
            <ConcernSummary 
              concerns={concerns} 
              filterStatus={statusFilter}
              onFilterChange={setStatusFilter}
            />

            <div className="flex flex-col gap-2">
              <label className="text-sm font-semibold text-gray-300">
                Search Concerns
              </label>
              <input
                type="text"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                placeholder="Search concerns..."
                className="p-3 bg-gray-700 border border-gray-600 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
              />
            </div>
          </div>
        </div>

        <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
          <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
            <table className="min-w-full leading-normal">
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <th
                        key={header.id}
                        onClick={header.column.getToggleSortingHandler()}
                        className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider cursor-pointer"
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        <span>
                          {{
                            asc: " 🔼",
                            desc: " 🔽",
                          }[header.column.getIsSorted()] ?? null}
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => (
                  <tr
                    key={row.id}
                    className="bg-white hover:bg-gray-100 transition-colors duration-150"
                  >
                    {row.getVisibleCells().map((cell) => (
                      <td
                        key={cell.id}
                        className="px-5 py-5 border-b border-gray-200 text-sm"
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="px-5 py-5 bg-gray-800 border-t border-gray-700 flex flex-col xs:flex-row items-center xs:justify-between">
          <div className="inline-flex rounded-full shadow-sm" role="group">
            <button
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
              className={`inline-flex items-center px-4 py-2 text-sm font-medium text-blue-300 bg-blue-900 rounded-l-full hover:bg-blue-800 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:outline-none transition-colors duration-200 ${
                !table.getCanPreviousPage() ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              <FontAwesomeIcon icon={faLongArrowAltLeft} className="mr-2" />
              Previous
            </button>
            <button
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
              className={`inline-flex items-center px-4 py-2 text-sm font-medium text-blue-300 bg-blue-900 rounded-r-full hover:bg-blue-800 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:outline-none transition-colors duration-200 ${
                !table.getCanNextPage() ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              Next
              <FontAwesomeIcon icon={faLongArrowAltRight} className="ml-2" />
            </button>
          </div>
          <span className="text-sm text-gray-300 mt-2 xs:mt-0">
            Page{" "}
            <strong>
              {table.getState().pagination.pageIndex + 1} of{" "}
              {table.getPageCount()}
            </strong>{" "}
            | Total {filteredConcerns.length} concerns
          </span>
        </div>
      </div>

      {selectedConcern && (
        <ConcernDetails
          concern={selectedConcern}
          onClose={() => setSelectedConcern(null)}
          userName={userName}
          isHR={isHR}
        />
      )}
    </div>
  );
}

export default Concerns;
