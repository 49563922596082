import React, { useState, useEffect, memo, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";
import { db } from "./firebase";
import * as XLSX from "xlsx";
import {
  format,
  startOfWeek,
  endOfWeek,
  subWeeks,
  eachDayOfInterval,
  parseISO,
} from "date-fns";
import { collection, query, where, getDocs } from "firebase/firestore";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Invoices = memo(() => {
  const [isLoading, setIsLoading] = useState(true);

  const fetchExcludedPositions = async () => {
    const excludedPositions = [];
    const locationsSnapshot = await getDocs(collection(db, "locations"));

    locationsSnapshot.forEach((doc) => {
      const rolesMap = doc.data().roles;
      if (rolesMap) {
        Object.keys(rolesMap).forEach((role) => {
          if (rolesMap[role] === "notByHour") {
            excludedPositions.push(role);
          }
        });
      }
    });

    return excludedPositions;
  };

  useEffect(() => {
    const fetchAndSetExcludedPositions = async () => {
      try {
        const excludedPositions = await fetchExcludedPositions();
        setExcludedPositions(excludedPositions);
      } catch (error) {
        console.error("Error fetching excluded positions: ", error);
      }
    };

    fetchAndSetExcludedPositions();
  }, []);

  const getLastWeekRange = () => {
    const today = new Date();
    const lastWeekStart = format(
      startOfWeek(subWeeks(today, 1), { weekStartsOn: 0 }),
      "yyyy-MM-dd"
    );
    const lastWeekEnd = format(
      endOfWeek(subWeeks(today, 1), { weekStartsOn: 0 }),
      "yyyy-MM-dd"
    );
    return { lastWeekStart, lastWeekEnd };
  };

  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [employees, setEmployees] = useState([]);
  const [startDate, setStartDate] = useState(getLastWeekRange().lastWeekStart);
  const [endDate, setEndDate] = useState(getLastWeekRange().lastWeekEnd);
  const [locationDropdownOpen, setLocationDropdownOpen] = useState(false);
  const [excludedPositions, setExcludedPositions] = useState([]);

  const EXCLUDED_POSITIONS = useMemo(
    () => excludedPositions,
    [excludedPositions]
  );

  useEffect(() => {
    const { lastWeekStart, lastWeekEnd } = getLastWeekRange();
    setStartDate(lastWeekStart);
    setEndDate(lastWeekEnd);
  }, []);

  useEffect(() => {
    // Function to calculate hours from timestamps
    const calculateHours = (roleChanges, endTimestamp, breakTimestamps) => {
      const roleTimes = roleChanges.map((change) => ({
        role: change.role,
        timestamp:
          change.timestamp instanceof Date
            ? change.timestamp
            : change.timestamp.toDate(),
      }));

      const end =
        endTimestamp instanceof Date
          ? endTimestamp
          : endTimestamp?.toDate() ?? new Date();

      roleTimes.push({ role: null, timestamp: end });

      const roleDurations = {};

      for (let i = 0; i < roleTimes.length - 1; i++) {
        const { role, timestamp } = roleTimes[i];
        const nextTimestamp = roleTimes[i + 1].timestamp;

        let shiftDuration = (nextTimestamp - timestamp) / 1000;

        if (breakTimestamps && breakTimestamps.length > 1) {
          for (let j = 0; j < breakTimestamps.length; j += 2) {
            const breakStart =
              breakTimestamps[j] instanceof Date
                ? breakTimestamps[j]
                : breakTimestamps[j]?.toDate
                ? breakTimestamps[j].toDate()
                : new Date(breakTimestamps[j]);
            const breakEnd =
              (breakTimestamps[j + 1] instanceof Date
                ? breakTimestamps[j + 1]
                : breakTimestamps[j + 1]?.toDate
                ? breakTimestamps[j + 1].toDate()
                : new Date(breakTimestamps[j + 1])) ?? new Date();

            if (breakStart >= timestamp && breakEnd <= nextTimestamp) {
              shiftDuration -= (breakEnd - breakStart) / 1000;
            }
          }
        }

        const shiftHours = shiftDuration / 3600;
        roleDurations[role] = (roleDurations[role] || 0) + shiftHours;
      }

      return roleDurations;
    };

    const fetchShifts = async () => {
      setIsLoading(true);
      try {
        const start = new Date(`${startDate}T00:00:00`);
        const end = new Date(`${endDate}T23:59:59`);

        const queryConditions = [
          collection(db, "shifts"),
          where("startTimestamp", ">=", start),
          where("startTimestamp", "<=", end),
          where("approvedBy", "!=", null),
        ];

        const shiftsQuery = query(...queryConditions);
        const querySnapshot = await getDocs(shiftsQuery);

        const shiftsData = {};
        const newLocations = new Set(); // Track unique locations

        querySnapshot.forEach((doc) => {
          const data = doc.data();
          if (data.locationName) {
            const trimmedLocation = data.locationName.trim();
            newLocations.add(trimmedLocation);
          }

          const roleChanges = data.roleChanges || [];
          const shiftStart = data.startTimestamp.toDate();
          const shiftEnd = data.endTimestamp ? data.endTimestamp.toDate() : null;
          const breaks = data.breakTimestamps || [];

          const employeeKey = data.userId || "missing-userId";
          if (!shiftsData[employeeKey]) {
            shiftsData[employeeKey] = {
              userId: employeeKey,
              firstName: data.firstName || "Unknown",
              lastName: data.lastName || "Unknown",
              positions: new Set(),
              locations: new Set([data.locationName?.trim()]),
              method: new Set([data.payMethod]),
              rates: new Set([parseFloat(data.rate) || 0]),
              dailyHours: {},
            };
          } else {
            shiftsData[employeeKey].locations.add(data.locationName?.trim());
          }

          const roleDurations = calculateHours(roleChanges, shiftEnd, breaks);
          const dayKey = format(shiftStart, "yyyy-MM-dd");

          // Initialize dailyHours[dayKey] as an object
          shiftsData[employeeKey].dailyHours[dayKey] = shiftsData[employeeKey].dailyHours[dayKey] || {};

          // Store hours by role within the dayKey, including location information
          Object.entries(roleDurations).forEach(([role, hours]) => {
            if (EXCLUDED_POSITIONS.includes(role)) {
              return;
            }
            shiftsData[employeeKey].positions.add(role);
            if (!shiftsData[employeeKey].dailyHours[dayKey][role]) {
              shiftsData[employeeKey].dailyHours[dayKey][role] = {};
            }
            shiftsData[employeeKey].dailyHours[dayKey][role][data.locationName.trim()] = hours;
          });
        });

        // Update locations state with the locations found in the current date range
        setLocations(Array.from(newLocations).sort());

        // Filter out employees with no valid positions
        const aggregatedShiftsData = Object.values(shiftsData)
          .filter((employee) => employee.positions.size > 0)
          .map((employee) => ({
            ...employee,
            positions: Array.from(employee.positions).join(", "),
            locations: Array.from(employee.locations).join(", "),
            method: Array.from(employee.method),
            rates: Array.from(employee.rates),
          }));

        setEmployees(aggregatedShiftsData);
      } catch (error) {
        console.error("Error fetching shifts:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchShifts().catch(console.error);
  }, [startDate, endDate, EXCLUDED_POSITIONS]);

  const convertHoursToHM = (hours) => {
    const totalMinutes = Math.floor(hours * 60);
    const hoursPart = Math.floor(totalMinutes / 60);
    const minutesPart = totalMinutes % 60;
    return `${hoursPart.toString().padStart(2, "0")}:${minutesPart
      .toString()
      .padStart(2, "0")}`;
  };

  const convertHoursToHMS = (hours) => {
    const totalSeconds = Math.floor(hours * 3600);
    const hoursPart = Math.floor(totalSeconds / 3600);
    const minutesPart = Math.floor((totalSeconds % 3600) / 60);
    const secondsPart = totalSeconds % 60;
    return `${hoursPart}:${minutesPart
      .toString()
      .padStart(2, "0")}:${secondsPart.toString().padStart(2, "0")}`;
  };

  // Helper function to parse HH:MM to decimal hours
  const parseHMToDecimal = (hms) => {
    if (typeof hms === "string") {
      const [hours, minutes, seconds] = hms.split(":").map(Number);
      return hours + minutes / 60 + (seconds || 0) / 3600;
    }
    return 0;
  };

  // Helper function to sum hours in HH:MM format
  const sumHM = (hoursArray) => {
    return hoursArray.reduce((acc, cur) => {
      if (cur === "Off" || cur === "0:00:00") return acc;
      return acc + parseHMToDecimal(cur);
    }, 0);
  };

  // Create a memoized filtered employees
  const filteredEmployees = useMemo(() => {
    if (!selectedLocation || selectedLocation === "All Locations") {
      return employees;
    }

    return employees.filter((emp) => {
      // Check if any of the employee's shifts have hours at the selected location
      return Object.values(emp.dailyHours).some(dayData => {
        return Object.values(dayData).some(positionData => {
          return Object.keys(positionData).includes(selectedLocation);
        });
      });
    });
  }, [employees, selectedLocation]);

  const renderInvoices = () => {
    const groups = groupEmployeesByLocationAndPosition(filteredEmployees);
    const filteredGroups = groups.filter(
      (group) => !EXCLUDED_POSITIONS.includes(group.position.trim())
    );

    return (
      <div className="p-8 bg-[#1F2937] min-h-screen text-white">
        <div className="mb-8">
          <h1 className="text-lg font-bold text-white uppercase tracking-wider mb-2">
            Invoices
          </h1>
          <p className="text-gray-400">Generate and export client invoices</p>
        </div>

        <div className="bg-white/10 backdrop-blur-sm rounded-lg p-6 mb-8 shadow-lg border border-white/20">
          <div className="flex flex-wrap items-center gap-4">
            {/* Date inputs */}
            <div className="flex-1 min-w-[200px]">
              <label className="block text-sm font-medium text-gray-200 mb-2">
                Start Date
              </label>
              <input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                className="w-full px-4 py-2 rounded-lg bg-white/5 border border-white/20 text-white focus:ring-2 focus:ring-blue-500 focus:border-blue-500 hover:border-blue-400 transition-all"
              />
            </div>
            <div className="flex-1 min-w-[200px]">
              <label className="block text-sm font-medium text-gray-200 mb-2">
                End Date
              </label>
              <input
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                className="w-full px-4 py-2 rounded-lg bg-white/5 border border-white/20 text-white focus:ring-2 focus:ring-blue-500 focus:border-blue-500 hover:border-blue-400 transition-all"
              />
            </div>

            {/* Location dropdown */}
            <div className="flex-1 min-w-[200px]">
              <label className="block text-sm font-medium text-gray-200 mb-2">
                Location
              </label>
              <div className="relative">
                <button
                  className="w-full px-4 py-2 rounded-lg bg-white/5 border border-white/20 text-white focus:ring-2 focus:ring-blue-500 focus:border-blue-500 hover:border-blue-400 transition-all flex items-center justify-between"
                  onClick={toggleLocationDropdown}
                >
                  <span>{selectedLocation || "Select Location"}</span>
                  <svg className="w-4 h-4" fill="none" viewBox="0 0 20 20">
                    <path
                      d="M5.5 7L10 11.5L14.5 7"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                {locationDropdownOpen && (
                  <div className="absolute z-10 w-full mt-1 bg-gray-800 border border-white/20 rounded-lg shadow-lg max-h-60 overflow-auto">
                    <button
                      className="w-full px-4 py-2 text-left text-white hover:bg-gray-700 transition-colors"
                      onClick={() => {
                        setSelectedLocation("All Locations");
                        setLocationDropdownOpen(false);
                      }}
                    >
                      All Locations
                    </button>
                    {locations.map((location) => (
                      <button
                        key={location}
                        className="w-full px-4 py-2 text-left text-white hover:bg-gray-700 transition-colors"
                        onClick={() => {
                          setSelectedLocation(location.trim());
                          setLocationDropdownOpen(false);
                        }}
                      >
                        {location.trim()}
                      </button>
                    ))}
                  </div>
                )}
              </div>
            </div>

            {/* Export button */}
            <div className="flex-1 min-w-[200px]">
              <label className="block text-sm font-medium text-gray-200 mb-2">
                Export
              </label>
              <button
                onClick={exportToExcel}
                className="w-full px-4 py-2 rounded-lg bg-blue-500 hover:bg-blue-600 text-white transition-colors flex items-center justify-center gap-2"
              >
                <FontAwesomeIcon icon={faFileExport} />
                Export Invoice
              </button>
            </div>
          </div>
        </div>

        {isLoading ? (
          // Loading state with skeletons
          <div className="space-y-8 overflow-x-auto">
            {[...Array(2)].map((_, groupIndex) => (
              <div key={groupIndex}>
                <h2 className="text-xl font-bold mb-4">
                  <Skeleton width={200} />
                </h2>
                <div className="overflow-x-auto">
                  <table className="min-w-full bg-white rounded-lg mb-6">
                    <thead className="bg-gray-200 text-gray-700">
                      <tr>
                        <th className="px-4 py-2">Employee</th>
                        {[...Array(4)].map((_, i) => (
                          <th key={i} className="px-4 py-2">
                            <Skeleton width={80} />
                          </th>
                        ))}
                        <th className="px-4 py-2">Total</th>
                      </tr>
                    </thead>
                    <tbody className="text-gray-700">
                      {[...Array(3)].map((_, rowIndex) => (
                        <tr key={rowIndex}>
                          <td className="border px-4 py-2">
                            <Skeleton width={100} />
                          </td>
                          {[...Array(4)].map((_, i) => (
                            <td key={i} className="border px-4 py-2">
                              <Skeleton width={50} />
                            </td>
                          ))}
                          <td className="border px-4 py-2">
                            <Skeleton width={60} />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot className="bg-gray-200 text-gray-700 font-bold">
                      <tr>
                        <th className="px-4 py-2 text-left">Total</th>
                        <th colSpan={5} className="px-4 py-2 text-right">
                          <Skeleton width={80} />
                        </th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            ))}
          </div>
        ) : (
          // Existing render logic for loaded state
          filteredGroups.map((group, index) => {
            const { location, position, employees: groupEmployees } = group;
            const dateRange = eachDayOfInterval({
              start: parseISO(startDate),
              end: parseISO(endDate),
            });

            const positionTotalHours = groupEmployees.reduce((total, emp) => {
              const dailyHoursArray = dateRange.map((date) => {
                const dateKey = format(date, "yyyy-MM-dd");
                return emp.dailyHours[dateKey]
                  ? convertHoursToHMS(emp.dailyHours[dateKey])
                  : "0:00:00"; // Use "0:00:00" as default value
              });
              return total + sumHM(dailyHoursArray);
            }, 0);

            return (
              <div key={`${location}-${position}-${index}`}>
                <h2 className="text-xl font-bold mb-4">
                  {location} ({position})
                </h2>
                <table className="min-w-full bg-white rounded-lg mb-6">
                  <thead className="bg-gray-200 text-gray-700">
                    <tr>
                      <th className="px-4 py-2">Employee</th>
                      {dateRange.map((date) => (
                        <th
                          key={format(date, "yyyy-MM-dd")}
                          className="px-4 py-2"
                        >
                          {format(date, "EEEE MMM d")}
                        </th>
                      ))}
                      <th className="px-4 py-2">Total</th>
                    </tr>
                  </thead>
                  <tbody className="text-gray-700">
                    {groupEmployees.map((employee, empIndex) => (
                      <tr key={employee.userId}>
                        <td
                          className={`border px-4 py-2 ${
                            empIndex === 0 ? "rounded-tl-lg" : ""
                          }`}
                        >
                          {employee.firstName} {employee.lastName}
                        </td>
                        {dateRange.map((date) => {
                          const dateKey = format(date, "yyyy-MM-dd");
                          const hours = employee.dailyHours[dateKey]
                            ? convertHoursToHMS(employee.dailyHours[dateKey])
                            : "Off";
                          return (
                            <td key={dateKey} className="border px-4 py-2">
                              {hours !== "Off" ? hours : "Off"}
                            </td>
                          );
                        })}
                        <td
                          className={`border px-4 py-2 ${
                            empIndex === groupEmployees.length - 1
                              ? "rounded-br-lg"
                              : ""
                          }`}
                        >
                          {convertHoursToHMS(
                            sumHM(
                              dateRange.map((date) => {
                                const dateKey = format(date, "yyyy-MM-dd");
                                return employee.dailyHours[dateKey]
                                  ? convertHoursToHMS(
                                      employee.dailyHours[dateKey]
                                    )
                                  : "0:00:00";
                              })
                            )
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot className="bg-gray-200 text-gray-700 font-bold">
                    <tr>
                      <th className="px-4 py-2 text-left">Total</th>
                      {dateRange.map((date) => (
                        <th key={format(date, "yyyy-MM-dd")} className="px-4 py-2">
                        </th>
                      ))}
                      <th className="px-4 py-2">
                        {convertHoursToHMS(positionTotalHours)}
                      </th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            );
          })
        )}
      </div>
    );
  };

  const groupEmployeesByLocationAndPosition = (employees) => {
    const grouped = {};
    employees.forEach((employee) => {
      employee.positions.split(", ").forEach((position) => {
        if (EXCLUDED_POSITIONS.includes(position)) {
          return;
        }
        
        const datesWithHours = Object.keys(employee.dailyHours);
        
        datesWithHours.forEach(date => {
          // Skip if no hours for this position on this date
          if (!employee.dailyHours[date][position]) {
            return;
          }
          
          // Get the locations where this employee worked this position on this date
          const locationsWorked = Object.keys(employee.dailyHours[date][position]);
          
          locationsWorked.forEach((location) => {
            // If a location is selected, skip other locations
            if (selectedLocation && selectedLocation !== "All Locations" && location !== selectedLocation) {
              return;
            }

            const key = `${location} - ${position}`;

            if (!grouped[key]) {
              grouped[key] = {
                location: location,
                position: position,
                employees: [],
              };
            }

            // Check if employee already exists in this group
            let existingEmployee = grouped[key].employees.find(e => e.userId === employee.userId);
            
            if (!existingEmployee) {
              existingEmployee = {
                ...employee,
                dailyHours: {},
                positions: position,
              };
              grouped[key].employees.push(existingEmployee);
            }

            // Only add hours for this specific date, position, and location
            if (employee.dailyHours[date][position][location]) {
              existingEmployee.dailyHours[date] = employee.dailyHours[date][position][location];
            }
          });
        });
      });
    });
    return Object.values(grouped);
  };

  const exportToExcel = () => {
    const wb = XLSX.utils.book_new();
    const wsData = [];
    const groupedPositions = groupEmployeesByLocationAndPosition(employees);

    // Define the date range header once
    const dateRange = eachDayOfInterval({
      start: parseISO(startDate),
      end: parseISO(endDate),
    });
    const dateRangeHeaders = dateRange.map((date) =>
      format(date, "EEEE MMM d")
    );

    // Filter out excluded positions before processing
    const filteredGroupedPositions = groupedPositions.filter(
      (group) => !EXCLUDED_POSITIONS.includes(group.position.trim())
    );

    filteredGroupedPositions.forEach((group) => {
      // Add the sheet title with location and position
      wsData.push([`${group.location} (${group.position})`]);

      // Add the header row
      wsData.push(["Employee", ...dateRangeHeaders, "Total"]);

      // Prepare data rows for each employee
      const groupData = group.employees.map((emp) => {
        const hoursData = dateRange.map((date) => {
          const dateKey = format(date, "yyyy-MM-dd");
          return emp.dailyHours[dateKey]
            ? convertHoursToHM(emp.dailyHours[dateKey])
            : "Off";
        });

        const totalHours = hoursData.reduce((total, current) => {
          return current !== "Off"
            ? total + parseHoursToDecimal(current)
            : total;
        }, 0);

        return [
          `${emp.firstName} ${emp.lastName}`,
          ...hoursData,
          convertHoursToHM(totalHours),
        ];
      });

      // Add the employee rows to the worksheet data
      wsData.push(...groupData);

      // Calculate total hours for this position
      const positionTotalDecimalHours = groupData.reduce((total, row) => {
        return total + parseHoursToDecimal(row[row.length - 1]);
      }, 0);

      // Add a row with the total hours for this position
      wsData.push([
        "Total",
        ...Array(dateRangeHeaders.length).fill(""),
        convertHoursToHM(positionTotalDecimalHours),
      ]);

      // Add an empty row after each group for better separation
      wsData.push([]);
    });

    // Create the worksheet from the consolidated data
    const ws = XLSX.utils.aoa_to_sheet(wsData);
    XLSX.utils.book_append_sheet(wb, ws, "ExportedData");

    // Generate Excel file and initiate download
    const filename = `export_${new Date()
      .toISOString()
      .replace(/[\W_]+/g, "_")}.xlsx`;
    XLSX.writeFile(wb, filename);
  };

  // Helper function to convert HH:MM to decimal hours
  const parseHoursToDecimal = (hhmm) => {
    // Check if input is a string and not "Off"
    if (typeof hhmm === "string" && hhmm !== "Off") {
      const [hours, minutes] = hhmm.split(":");
      return parseInt(hours, 10) + parseInt(minutes, 10) / 60;
    }
    // If input is "Off" or not a string, return 0
    return 0;
  };

  // Function to toggle the location dropdown
  const toggleLocationDropdown = () => {
    setLocationDropdownOpen(!locationDropdownOpen);
  };

  return (
    <div className="bg-[#1F2937] min-h-screen text-white p-8">
      <div className="flex justify-center mb-6"></div>
      {renderInvoices()}
    </div>
  );
});

export default Invoices;
