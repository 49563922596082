import React, { useState, useMemo } from "react";
import { db } from "./firebase";
import {
  collection,
  query,
  getDocs,
  where,
  Timestamp,
  orderBy,
  limit,
  doc,
  getDoc,
} from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileExport,
  faSearch,
  faArrowDownAZ,
  faArrowDownZA,
} from "@fortawesome/free-solid-svg-icons";
import * as XLSX from "xlsx";
import { useQuery } from "@tanstack/react-query";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  flexRender,
} from "@tanstack/react-table";

const Purgatory = () => {
  const [sorting, setSorting] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const EXCLUDED_ROLES = ['owner', 'HR', 'consultant', 'admin'];
  const EXCLUDED_USER_ID = 'QdIwzt9dyEakcYHDsr7JQmvniZ43';

  const fetchData = async () => {
    // Fetch all active users
    const usersRef = collection(db, "users");
    const usersQuery = query(
      usersRef,
      where("status", "==", "Active")
    );
    const usersSnapshot = await getDocs(usersQuery);
    const activeUsers = usersSnapshot.docs
      .map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }))
      .filter(user => 
        user.id !== EXCLUDED_USER_ID && 
        (!user.role || !EXCLUDED_ROLES.includes(user.role.toLowerCase()))
      );

    // Fetch shifts from the last 30 days
    const shiftsRef = collection(db, "shifts");
    const thirtyDaysAgo = Timestamp.fromDate(
      new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)
    );
    const shiftsQuery = query(
      shiftsRef,
      where("startTimestamp", ">=", thirtyDaysAgo)
    );
    const shiftsSnapshot = await getDocs(shiftsQuery);
    const recentShifts = shiftsSnapshot.docs.map((doc) => doc.data());

    // Create a map of userId to their latest shift date
    const latestShiftMap = new Map();
    recentShifts.forEach((shift) => {
      const userId = shift.userId;
      const shiftDate = shift.startTimestamp.toDate();
      if (
        !latestShiftMap.has(userId) ||
        shiftDate > latestShiftMap.get(userId)
      ) {
        latestShiftMap.set(userId, shiftDate);
      }
    });

    // Identify users without recent shifts and get their latest shift date
    const usersWithoutRecentShifts = activeUsers.filter(
      (user) => !latestShiftMap.has(user.id)
    );

    // Fetch the latest shift for users without recent shifts
    const usersWithoutRecentShiftsData = await Promise.all(
      usersWithoutRecentShifts.map(async (user) => {
        const latestShiftQuery = query(
          shiftsRef,
          where("userId", "==", user.id),
          orderBy("startTimestamp", "desc"),
          limit(1)
        );
        const latestShiftSnapshot = await getDocs(latestShiftQuery);
        const latestShift = latestShiftSnapshot.docs[0]?.data();

        // Fetch reportsTo user details
        let reportsToNames = [];
        if (Array.isArray(user.reportsTo) && user.reportsTo.length > 0) {
          for (const reportsToId of user.reportsTo) {
            try {
              const reportsToDocRef = doc(db, "users", reportsToId.trim());
              const reportsToDocSnap = await getDoc(reportsToDocRef);

              if (reportsToDocSnap.exists()) {
                const reportsToData = reportsToDocSnap.data();
                const firstName = reportsToData.firstName || "";
                const lastName = reportsToData.lastName || "";
                const fullName = `${firstName} ${lastName}`.trim();
                if (fullName) reportsToNames.push(fullName);
              } else {
              }
            } catch (error) {
              console.error("Error fetching reportsTo user:", error);
            }
          }
        } else {
        }

        return {
          id: user.id,
          firstName: user.firstName || "Unknown",
          lastName: user.lastName || "Unknown",
          location: user.workLocation || "Unknown",
          reportsTo:
            reportsToNames.length > 0 ? reportsToNames.join(", ") : "Unknown",
          latestShiftDate: latestShift
            ? latestShift.startTimestamp.toDate()
            : null,
          hiringDate: user.hiringDate || "Unknown",
          role: user.role || "Unknown",
        };
      })
    );

    return usersWithoutRecentShiftsData;
  };

  const {
    data: employeesWithoutShifts,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["employeesWithoutShifts"],
    queryFn: fetchData,
  });

  const columns = useMemo(
    () => [
      {
        accessorKey: "firstName",
        header: "First Name",
      },
      {
        accessorKey: "lastName",
        header: "Last Name",
      },
      {
        accessorKey: "role",
        header: "Role",
      },
      {
        accessorKey: "location",
        header: "Work Location",
      },
      {
        accessorKey: "reportsTo",
        header: "Reports To",
      },
      {
        accessorKey: "latestShiftDate",
        header: "Latest Shift Date",
        cell: ({ getValue }) => {
          const value = getValue();
          return value ? value.toLocaleDateString() : "No shifts found";
        },
      },
      {
        accessorKey: "hiringDate",
        header: "Hiring Date",
        cell: ({ getValue }) => {
          const value = getValue();
          return value && value !== "Unknown"
            ? new Date(value).toLocaleDateString()
            : value;
        },
      },
    ],
    []
  );

  const table = useReactTable({
    data: employeesWithoutShifts || [],
    columns,
    state: {
      sorting,
      globalFilter: searchTerm,
    },
    onSortingChange: setSorting,
    onGlobalFilterChange: setSearchTerm,
    globalFilterFn: "contains",
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  const exportToExcel = () => {
    const wb = XLSX.utils.book_new();
    const wsHeader = [
      [
        "First Name",
        "Last Name",
        "Work Location",
        "Reports To",
        "Latest Shift Date",
        "Hiring Date",
      ],
    ];

    const wsData = employeesWithoutShifts.map((employee) => [
      employee.firstName,
      employee.lastName,
      employee.location,
      employee.reportsTo,
      employee.latestShiftDate
        ? employee.latestShiftDate.toLocaleDateString()
        : "No shifts found",
      employee.hiringDate && employee.hiringDate !== "Unknown"
        ? new Date(employee.hiringDate).toLocaleDateString()
        : employee.hiringDate,
    ]);

    const ws = XLSX.utils.aoa_to_sheet(wsHeader.concat(wsData));
    XLSX.utils.book_append_sheet(wb, ws, "Employees Without Recent Shifts");

    const filename = `employees_without_recent_shifts_${
      new Date().toISOString().split("T")[0]
    }.xlsx`;
    XLSX.writeFile(wb, filename);
  };

  const getResultText = (count, isLoading) => {
    if (isLoading) return "Loading results...";
    if (count === 0) return "No results found";
    return `${count} result${count !== 1 ? "s" : ""} found`;
  };

  if (error) {
    return (
      <div className="bg-[#1F2937] min-h-screen text-white p-8">
        Error: {error.message}
      </div>
    );
  }

  return (
    <div className="bg-[#1F2937] min-h-screen text-white p-8">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Employees Without Recent Shifts</h1>
        <button
          onClick={exportToExcel}
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
        >
          <FontAwesomeIcon icon={faFileExport} className="mr-2" /> Export
        </button>
      </div>

      <div className="mb-4">
        <div className="relative">
          <input
            type="text"
            placeholder="Search by name or location..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full px-4 py-2 text-gray-700 bg-white border rounded-full focus:border-blue-500 focus:outline-none focus:ring"
          />
          <FontAwesomeIcon
            icon={faSearch}
            className="absolute right-3 top-3 text-gray-400"
          />
        </div>
        <div className="mt-2 text-white font-semibold text-grey-600 uppercase tracking-wider">
          {getResultText(employeesWithoutShifts?.length, isLoading)}
        </div>
      </div>

      {isLoading ? (
        <table className="min-w-full bg-white rounded-lg">
          <thead className="bg-gray-200 text-gray-700">
            <tr>
              {columns.map((column) => (
                <th key={column.header} className="px-4 py-2">
                  <div className="flex items-center justify-between">
                    <span>{column.header}</span>
                    <FontAwesomeIcon
                      icon={faArrowDownAZ}
                      className="ml-1 opacity-50"
                    />
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="text-gray-700">
            {[...Array(5)].map((_, index) => (
              <tr key={index}>
                {[...Array(7)].map((_, cellIndex) => (
                  <td key={cellIndex} className="border px-4 py-2">
                    <Skeleton />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <table className="min-w-full bg-white rounded-lg">
          <thead className="bg-gray-200 text-gray-700">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className="px-4 py-2 cursor-pointer"
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    <div className="flex items-center justify-between">
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      <FontAwesomeIcon
                        icon={
                          header.column.getIsSorted() === "desc"
                            ? faArrowDownZA
                            : faArrowDownAZ
                        }
                        className={`ml-1 ${
                          header.column.getIsSorted() ? "opacity-100" : "opacity-50"
                        }`}
                      />
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="text-gray-700">
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id} className="border px-4 py-2">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Purgatory;
