import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport, faCheck } from "@fortawesome/free-solid-svg-icons";
import { db } from "./firebase";
import { useQuery } from "@tanstack/react-query";
import * as XLSX from "xlsx";
import { collection, query, where, getDocs } from "firebase/firestore";
import { calculateRoleHours, convertHoursToHM } from "./timeCalculations";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useRanger } from "@tanstack/react-ranger";

const Reports = () => {
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("All Locations");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [locationDropdownOpen, setLocationDropdownOpen] = useState(false);
  const [minHours, setMinHours] = useState(0);
  const [maxHours, setMaxHours] = useState(0);
  const [sliderValue, setSliderValue] = useState(0);
  const [showApprovedOnly, setShowApprovedOnly] = useState(true);
  const [showExportOptions, setShowExportOptions] = useState(false);

  const rangerRef = React.useRef(null);
  const rangerInstance = useRanger({
    getRangerElement: () => rangerRef.current,
    values: [sliderValue],
    min: 0,
    max: maxHours,
    stepSize: 0.5,
    onDrag: (instance) => setSliderValue(instance.sortedValues[0]),
  });

  const locationDropdownRef = useRef(null);
  const exportDropdownRef = useRef(null);

  const fetchShiftsData = async ({ queryKey }) => {
    const {
      startDate,
      endDate,
      selectedLocation,
      selectedPosition,
      showApprovedOnly,
    } = queryKey[1];
    
    // First, fetch only Active salaried users
    const salariedUsersQuery = query(
      collection(db, "users"),
      where("status", "==", "Active"), // Only Active users for salary
      where("salary", "!=", "")  // Changed from null to catch all non-empty salary values
    );
    const salariedUsersSnapshot = await getDocs(salariedUsersQuery);
    let shiftsData = {};

    // Add salaried users to shiftsData first
    salariedUsersSnapshot.docs.forEach(doc => {
      const userData = doc.data();
      const salaryNumber = parseFloat(userData.salary) || 0;
      if (salaryNumber > 0) {
        shiftsData[userData.uid] = {
          userId: userData.uid,
          firstName: userData.firstName || "Unknown",
          lastName: userData.lastName || "Unknown",
          companyName: userData.companyName || "",
          positions: {},
          locations: {},
          method: new Set([userData.payMethod || ""]),
          hours: 0,
          rate: 0,
          total: 0,
          weeklySalary: salaryNumber / 52,
          payMethod: userData.payMethod || "",
          role: userData.role || "",
          status: "Active",
          regularHours: 0,
          overtimeHours: 0,
        };
      }
    });

    // Continue with existing shifts query logic
    const start = startDate ? new Date(startDate + "T00:00:00") : null;
    const end = endDate ? new Date(endDate + "T23:59:59") : null;

    let queryConditions = [collection(db, "shifts")];

    if (selectedLocation && selectedLocation !== "All Locations") {
      queryConditions.push(where("locationName", "==", selectedLocation));
    }

    if (selectedPosition && selectedPosition !== "All Positions") {
      queryConditions.push(
        where("roleChanges", "array-contains", { role: selectedPosition })
      );
    }

    if (showApprovedOnly) {
      queryConditions.push(where("approvedBy", "!=", null));
    }

    if (start) {
      queryConditions.push(where("startTimestamp", ">=", start));
    }

    if (end) {
      queryConditions.push(where("startTimestamp", "<=", end));
    }

    let shiftsQuery = query(...queryConditions);
    const querySnapshot = await getDocs(shiftsQuery);

    const userDataPromises = querySnapshot.docs.map(async (doc) => {
      const data = doc.data();

      try {
        const userDoc = await getDocs(
          query(
            collection(db, "users"),
            where("uid", "==", data.userId)
          )
        );
        const userData = userDoc.empty ? null : userDoc.docs[0].data();
        
        // Check if user has a valid salary value
        const hasSalary = userData?.salary && parseFloat(userData.salary) > 0;
        
        // If they have a salary but are not Active, skip them
        if (hasSalary && userData?.status !== "Active") {
          return null;
        }

        const userRate = !hasSalary ? (parseFloat(userData?.rate) || 0) : 0;
        const weeklySalary = hasSalary ? parseFloat(userData.salary) / 52 : 0;

        const roleHours = calculateRoleHours(
          data.startTimestamp,
          data.endTimestamp,
          data.roleChanges,
          data.breakTimestamps
        );

        return {
          ...data,
          userRate,
          roleHours,
          companyName: userData?.companyName || "",
          weeklySalary,
          payMethod: userData?.payMethod || "",
          role: userData?.role || "",
          status: userData?.status || "Active",
        };
      } catch (error) {
        console.error("Error fetching user data:", error);
        return null;
      }
    });

    const results = await Promise.all(userDataPromises);

    results.forEach((result) => {
      if (!result) return;

      const employeeKey = result.userId || "missing-userId";
      if (!shiftsData[employeeKey]) {
        shiftsData[employeeKey] = {
          userId: employeeKey,
          firstName: result.firstName || "Unknown",
          lastName: result.lastName || "Unknown",
          companyName: result.companyName || "",
          positions: {},
          locations: {},
          method: new Set(),
          hours: 0,
          rate: result.userRate,
          total: 0,
          weeklySalary: result.weeklySalary,
          payMethod: result.payMethod,
          role: result.role,
          status: result.status,
          regularHours: 0,
          overtimeHours: 0,
        };
      }

      Object.entries(result.roleHours).forEach(([role, hours]) => {
        if (!shiftsData[employeeKey].positions[role]) {
          shiftsData[employeeKey].positions[role] = 0;
        }
        shiftsData[employeeKey].positions[role] += hours;

        if (!shiftsData[employeeKey].locations[result.locationName]) {
          shiftsData[employeeKey].locations[result.locationName] = {
            total: 0,
            roles: {},
          };
        }
        shiftsData[employeeKey].locations[result.locationName].total += hours;
        shiftsData[employeeKey].locations[result.locationName].roles[role] =
          (shiftsData[employeeKey].locations[result.locationName].roles[role] ||
            0) + hours;

        shiftsData[employeeKey].hours += hours;

        // Check both payMethod and role for overtime eligibility
        const isEligibleForOvertime = 
          result.payMethod === '1099' && 
          result.role?.toLowerCase() === 'employee';

        if (isEligibleForOvertime) {
          const regularHours = Math.min(40, shiftsData[employeeKey].hours);
          const overtimeHours = Math.max(0, shiftsData[employeeKey].hours - 40);
          shiftsData[employeeKey].regularHours = regularHours;
          shiftsData[employeeKey].overtimeHours = overtimeHours;
          shiftsData[employeeKey].total = 
            (regularHours * result.userRate) + 
            (overtimeHours * result.userRate * 1.5);
        } else {
          // All other cases (non-1099 or non-employee roles) get regular pay
          shiftsData[employeeKey].regularHours = shiftsData[employeeKey].hours;
          shiftsData[employeeKey].overtimeHours = 0;
          shiftsData[employeeKey].total = shiftsData[employeeKey].hours * result.userRate;
        }
      });

      shiftsData[employeeKey].method.add(result.payMethod);
    });

    let filteredShiftsData = Object.values(shiftsData);

    const maxHours = Math.max(...filteredShiftsData.map((emp) => emp.hours), 0);
    const minHours = Math.min(...filteredShiftsData.map((emp) => emp.hours), 0);
    setMaxHours(maxHours);
    setMinHours(minHours);
    setSliderValue(minHours);

    return filteredShiftsData.map((employee) => ({
      ...employee,
      positions: employee.hours === 0 && employee.status === "Active" ? "Salary Only" : Object.entries(employee.positions)
        .map(([role, hours]) => `${role}: ${convertHoursToHM(hours)}`)
        .join(", "),
      locations: employee.hours === 0 && employee.status === "Active" ? "-" : Object.entries(employee.locations)
        .map(([location]) => location)
        .join(", "),
      hours: Number(employee.hours),
      total: employee.hours === 0 && employee.status === "Active" ? employee.weeklySalary : Number(employee.total),
      positionsDetail: employee.positions,
      locationsDetail: employee.locations,
    }));
  };

  const getDefaultDateRange = useCallback(() => {
    const today = new Date();
    const dayOfWeek = today.getDay();
    const lastSunday = new Date(today);
    lastSunday.setDate(today.getDate() - dayOfWeek - 7);
    const lastSaturday = new Date(today);
    lastSaturday.setDate(today.getDate() - dayOfWeek - 1);

    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    };

    return {
      startDate: formatDate(lastSunday),
      endDate: formatDate(lastSaturday),
    };
  }, []);

  useEffect(() => {
    const { startDate, endDate } = getDefaultDateRange();
    setStartDate(startDate);
    setEndDate(endDate);
  }, [getDefaultDateRange]);

  const { data: allEmployees = [], isLoading } = useQuery({
    queryKey: [
      "shifts",
      {
        startDate,
        endDate,
        showApprovedOnly,
      },
    ],
    queryFn: fetchShiftsData,
    enabled: !!startDate && !!endDate,
  });

  const filteredEmployees = useMemo(() => {
    return allEmployees
      .map((employee) => {
        if (selectedLocation === "All Locations") {
          return employee;
        }

        const locationData = employee.locationsDetail[selectedLocation];
        if (!locationData) {
          return null;
        }

        const hours = locationData.total;
        const positionsForLocation = Object.entries(locationData.roles)
          .map(([role, roleHours]) => `${role}: ${convertHoursToHM(roleHours)}`)
          .join(", ");

        return {
          ...employee,
          positions: positionsForLocation,
          locations: selectedLocation,
          hours: hours,
          total: hours * employee.rate,
        };
      })
      .filter(Boolean);
  }, [allEmployees, selectedLocation]);

  useEffect(() => {
    const fetchLocationsAndPositions = async () => {
      if (!startDate || !endDate) {
        console.error("Invalid startDate or endDate");
        return;
      }

      const start = new Date(`${startDate}T00:00:00`);
      const end = new Date(`${endDate}T23:59:59`);

      if (isNaN(start) || isNaN(end)) {
        console.error("Invalid start or end date");
        return;
      }

      const shiftsRef = collection(db, "shifts");
      const queryConstraints = [
        where("startTimestamp", ">=", start),
        where("startTimestamp", "<=", end),
      ];

      const shiftsQuery = query(shiftsRef, ...queryConstraints);
      const querySnapshot = await getDocs(shiftsQuery);

      const newLocations = new Set();

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        const { locationName } = data;

        if (locationName) {
          newLocations.add(locationName);
        }
      });

      setLocations(Array.from(newLocations).sort());
    };

    if (startDate && endDate) {
      fetchLocationsAndPositions().catch(console.error);
    }
  }, [startDate, endDate]);

  const toggleLocationDropdown = useCallback(() => {
    setLocationDropdownOpen((prev) => !prev);
  }, []);

  const exportToExcel = useCallback(
    (exportType = 'grouped') => {
      const wb = XLSX.utils.book_new();
      
      if (exportType === 'grouped') {
        const dataToExport = filteredEmployees.filter(
          (emp) => emp.hours >= sliderValue
        );

        const ws = XLSX.utils.aoa_to_sheet([
          [
            "Name",
            "Status",
            "UID",
            "Position",
            "Location",
            "Hours",
            "Method",
            "Rate",
            "Regular Pay",
            "OT Pay (1.5x)",
            "Total ($)",
            "Weekly Salary",
          ],
          ...dataToExport.map((emp) => [
            emp.companyName || `${emp.lastName}, ${emp.firstName}`,
            emp.status || "Active",
            emp.userId,
            emp.positions,
            emp.locations,
            convertHoursToHM(emp.hours),
            emp.payMethod,
            `$${emp.rate.toFixed(2)}`,
            emp.payMethod === '1099' ? `$${(emp.rate * emp.regularHours).toFixed(2)}` : '-',
            emp.payMethod === '1099' ? `$${(emp.rate * 1.5 * emp.overtimeHours).toFixed(2)}` : '-',
            `$${emp.total.toFixed(2)}`,
            emp.weeklySalary ? `$${emp.weeklySalary.toFixed(2)}` : '-',
          ]),
        ]);
        XLSX.utils.book_append_sheet(wb, ws, "Report");
      } else if (exportType === 'stacked') {
        let allData = [
          [
            "Name",
            "Status",
            "UID",
            "Position",
            "Location",
            "Hours",
            "Method",
            "Rate",
            "Regular Pay",
            "OT Pay (1.5x)",
            "Total ($)",
            "Weekly Salary",
          ]
        ];

        for (const location of locations) {
          const locationData = allEmployees
            .map((employee) => {
              const locationData = employee.locationsDetail[location];
              if (!locationData) return null;

              const hours = locationData.total;
              const positionsForLocation = Object.entries(locationData.roles)
                .map(
                  ([role, roleHours]) =>
                    `${role}: ${convertHoursToHM(roleHours)}`
                )
                .join(", ");

              // Calculate proportional salary for this location
              let weeklySalary = 0;
              if (employee.weeklySalary) {
                if (employee.hours > 0) {
                  // If they worked hours, distribute salary proportionally
                  weeklySalary = (hours / employee.hours) * employee.weeklySalary;
                } else {
                  // If they're salary-only, only show in first location they're associated with
                  const firstLocation = Object.keys(employee.locationsDetail)[0];
                  weeklySalary = location === firstLocation ? employee.weeklySalary : 0;
                }
              }

              return {
                ...employee,
                positions: positionsForLocation,
                locations: location,
                hours: hours,
                total: employee.weeklySalary ? weeklySalary : hours * employee.rate,
                weeklySalary: weeklySalary || 0
              };
            })
            .filter(Boolean)
            .filter((emp) => emp.hours >= sliderValue || emp.weeklySalary > 0);

          if (locationData.length > 0) {
            allData.push(
              ...locationData.map((emp) => [
                emp.companyName || `${emp.lastName}, ${emp.firstName}`,
                emp.status || "Active",
                emp.userId,
                emp.positions,
                emp.locations,
                convertHoursToHM(emp.hours),
                emp.payMethod,
                `$${emp.rate.toFixed(2)}`,
                emp.payMethod === '1099' ? `$${(emp.rate * emp.regularHours).toFixed(2)}` : '-',
                emp.payMethod === '1099' ? `$${(emp.rate * 1.5 * emp.overtimeHours).toFixed(2)}` : '-',
                `$${emp.total.toFixed(2)}`,
                emp.weeklySalary > 0 ? `$${emp.weeklySalary.toFixed(2)}` : '-',
              ])
            );
          }
        }

        const ws = XLSX.utils.aoa_to_sheet(allData);
        XLSX.utils.book_append_sheet(wb, ws, "Report");
      }

      // Generate filename with date range and current time
      const startDateObj = new Date(startDate);
      const endDateObj = new Date(endDate);
      const currentTime = new Date()
        .toLocaleTimeString("en-US", {
          hour12: false,
          hour: "2-digit",
          minute: "2-digit",
        })
        .replace(":", "");

      const filename = `All_Locations_${
        startDateObj.getMonth() + 1
      }-${startDateObj.getDate()}_${
        endDateObj.getMonth() + 1
      }-${endDateObj.getDate()}_${currentTime}.xlsx`;

      XLSX.writeFile(wb, filename);
    },
    [filteredEmployees, allEmployees, locations, sliderValue, startDate, endDate]
  );

  // Add this new useEffect to update min and max hours when filteredEmployees changes
  useEffect(() => {
    if (filteredEmployees.length > 0) {
      const hours = filteredEmployees.map((emp) => emp.hours);
      setMaxHours(Math.max(...hours));
      setMinHours(0);
      setSliderValue(0);
    } else {
      setMaxHours(0);
      setMinHours(0);
      setSliderValue(0);
    }
  }, [filteredEmployees]);

  // Add this useEffect to handle click outside
  useEffect(() => {
    function handleClickOutside(event) {
      // Handle location dropdown
      if (
        locationDropdownRef.current &&
        !locationDropdownRef.current.contains(event.target)
      ) {
        setLocationDropdownOpen(false);
      }
      // Handle export dropdown
      if (
        exportDropdownRef.current &&
        !exportDropdownRef.current.contains(event.target)
      ) {
        setShowExportOptions(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Update the renderReports useMemo to use filteredEmployees for displaying data
  const renderReports = useMemo(() => {
    const dataToDisplay = filteredEmployees.filter(
      (emp) => emp.hours >= sliderValue
    );

    return (
      <div className="p-8 bg-[#1F2937] min-h-screen text-white">
        <div className="mb-8">
          <h1 className="text-lg font-bold text-white uppercase tracking-wider mb-2">
            Reports
          </h1>
          <p className="text-gray-400">Generate and export employee reports</p>
        </div>

        <div className="bg-white/10 backdrop-blur-sm rounded-lg p-6 mb-8 shadow-lg border border-white/20">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
            {/* Date inputs */}
            <div>
              <label className="block text-sm font-medium text-gray-200 mb-2">
                Start Date
              </label>
              <input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                className="w-full px-4 py-2 rounded-lg bg-white/5 border border-white/20 text-white focus:ring-2 focus:ring-blue-500 focus:border-blue-500 hover:border-blue-400 transition-all"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-200 mb-2">
                End Date
              </label>
              <input
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                className="w-full px-4 py-2 rounded-lg bg-white/5 border border-white/20 text-white focus:ring-2 focus:ring-blue-500 focus:border-blue-500 hover:border-blue-400 transition-all"
              />
            </div>

            {/* Location dropdown */}
            <div>
              <label className="block text-sm font-medium text-gray-200 mb-2">
                Location
              </label>
              <div className="relative" ref={locationDropdownRef}>
                <button
                  className="w-full px-4 py-2 rounded-lg bg-white/5 border border-white/20 text-white focus:ring-2 focus:ring-blue-500 focus:border-blue-500 hover:border-blue-400 transition-all flex items-center justify-between"
                  onClick={toggleLocationDropdown}
                >
                  <span>{selectedLocation || "Select Location"}</span>
                  <svg className="w-4 h-4" fill="none" viewBox="0 0 20 20">
                    <path
                      d="M5.5 7L10 11.5L14.5 7"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                {locationDropdownOpen && (
                  <div className="absolute z-10 w-full mt-1 bg-gray-800 border border-white/20 rounded-lg shadow-lg max-h-60 overflow-auto">
                    <button
                      className="w-full px-4 py-2 text-left text-white hover:bg-gray-700 transition-colors"
                      onClick={() => {
                        setSelectedLocation("All Locations");
                        setLocationDropdownOpen(false);
                      }}
                    >
                      All Locations
                    </button>
                    {locations.map((location) => (
                      <button
                        key={location}
                        className="w-full px-4 py-2 text-left text-white hover:bg-gray-700 transition-colors"
                        onClick={() => {
                          setSelectedLocation(location);
                          setLocationDropdownOpen(false);
                        }}
                      >
                        {location}
                      </button>
                    ))}
                  </div>
                )}
              </div>
            </div>

            {/* Hours slider */}
            <div>
              <label className="block text-sm font-medium text-gray-200 mb-2">
                Minimum Hours
              </label>
              <div className="px-2">
                <div
                  ref={rangerRef}
                  className="relative h-2 bg-gray-700 rounded-lg"
                  style={{ userSelect: "none" }}
                >
                  <div
                    className="absolute h-full bg-blue-500 rounded-lg"
                    style={{
                      width: `${
                        ((sliderValue - minHours) / (maxHours - minHours)) * 100
                      }%`,
                    }}
                  />
                  {rangerInstance
                    .handles()
                    .map(
                      (
                        {
                          value,
                          onKeyDownHandler,
                          onMouseDownHandler,
                          onTouchStart,
                          isActive,
                        },
                        i
                      ) => (
                        <button
                          key={i}
                          onKeyDown={onKeyDownHandler}
                          onMouseDown={onMouseDownHandler}
                          onTouchStart={onTouchStart}
                          role="slider"
                          aria-valuemin={rangerInstance.options.min}
                          aria-valuemax={rangerInstance.options.max}
                          aria-valuenow={value}
                          className={`absolute top-1/2 -translate-y-1/2 w-4 h-4 bg-white rounded-full shadow focus:outline-none focus:ring-2 focus:ring-blue-500 cursor-grab active:cursor-grabbing ${
                            isActive ? "z-10" : "z-0"
                          }`}
                          style={{
                            left: `${rangerInstance.getPercentageForValue(
                              value
                            )}%`,
                            transform: "translate(-50%, -50%)",
                          }}
                        />
                      )
                    )}
                </div>
                <div className="mt-2 text-right">
                  <span className="text-white text-sm bg-gray-700 px-2 py-1 rounded">
                    {convertHoursToHM(sliderValue)}
                  </span>
                </div>
              </div>
            </div>

            {/* Approved only toggle */}
            <div>
              <label className="block text-sm font-medium text-gray-200 mb-2">
                Filter
              </label>
              <button
                onClick={() => setShowApprovedOnly(!showApprovedOnly)}
                className={`w-full px-4 py-2 rounded-lg flex items-center justify-center gap-2 transition-all ${
                  showApprovedOnly
                    ? "bg-green-500/20 text-green-300 border border-green-500"
                    : "bg-white/5 text-gray-300 border border-white/20"
                }`}
              >
                <FontAwesomeIcon
                  icon={faCheck}
                  className={showApprovedOnly ? "opacity-100" : "opacity-50"}
                />
                Approved Only
              </button>
            </div>

            {/* Export button */}
            <div>
              <label className="block text-sm font-medium text-gray-200 mb-2">
                Export
              </label>
              <div className="relative" ref={exportDropdownRef}>
                <button
                  onClick={() => {
                    if (selectedLocation !== "All Locations") {
                      exportToExcel(false);
                    } else {
                      setShowExportOptions((prev) => !prev);
                    }
                  }}
                  className="w-full px-4 py-2 rounded-lg bg-blue-500 hover:bg-blue-600 text-white transition-colors flex items-center justify-center gap-2"
                >
                  <FontAwesomeIcon icon={faFileExport} />
                  Export Report
                  {selectedLocation === "All Locations" && (
                    <svg className="w-4 h-4" fill="none" viewBox="0 0 20 20">
                      <path
                        d="M5.5 7L10 11.5L14.5 7"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                </button>
                {showExportOptions && selectedLocation === "All Locations" && (
                  <div className="absolute right-0 mt-1 w-56 rounded-lg shadow-lg bg-gray-800 border border-white/20">
                    <div className="py-1">
                      <button
                        onClick={() => {
                          exportToExcel('grouped');
                          setShowExportOptions(false);
                        }}
                        className="block w-full text-left px-4 py-2 text-sm text-white hover:bg-gray-700 transition-colors"
                      >
                        Export Combined
                      </button>
                      <button
                        onClick={() => {
                          exportToExcel('stacked');
                          setShowExportOptions(false);
                        }}
                        className="block w-full text-left px-4 py-2 text-sm text-white hover:bg-gray-700 transition-colors"
                      >
                        Export Stacked
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {isLoading ? (
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white rounded-lg">
              <thead className="bg-gray-200 text-gray-700">
                <tr>
                  <th className="px-4 py-2 rounded-tl-lg">Name</th>
                  <th className="px-4 py-2">Status</th>
                  <th className="px-4 py-2 w-24 break-words">UID</th>
                  <th className="px-4 py-2">Position</th>
                  <th className="px-4 py-2">Location</th>
                  <th className="px-4 py-2">Total Hours</th>
                  <th className="px-4 py-2">Method</th>
                  <th className="px-4 py-2">Rate</th>
                  <th className="px-4 py-2">Regular</th>
                  <th className="px-4 py-2">OT (1.5x)</th>
                  <th className="px-4 py-2">Total</th>
                  <th className="px-4 py-2 rounded-tr-lg">Weekly Salary</th>
                </tr>
              </thead>
              <tbody className="text-gray-700">
                {[...Array(5)].map((_, index) => (
                  <tr key={index}>
                    <td className="border px-4 py-2">
                      <Skeleton />
                    </td>
                    <td className="border px-4 py-2">
                      <Skeleton />
                    </td>
                    <td className="border px-4 py-2">
                      <Skeleton />
                    </td>
                    <td className="border px-4 py-2">
                      <Skeleton />
                    </td>
                    <td className="border px-4 py-2">
                      <Skeleton />
                    </td>
                    <td className="border px-4 py-2">
                      <Skeleton />
                    </td>
                    <td className="border px-4 py-2">
                      <Skeleton />
                    </td>
                    <td className="border px-4 py-2">
                      <Skeleton />
                    </td>
                    <td className="border px-4 py-2">
                      <Skeleton />
                    </td>
                    <td className="border px-4 py-2">
                      <Skeleton />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white rounded-lg">
              <thead className="bg-gray-200 text-gray-700">
                <tr>
                  <th className="px-4 py-2 rounded-tl-lg">Name</th>
                  <th className="px-4 py-2">Status</th>
                  <th className="px-4 py-2 w-24 break-words">UID</th>
                  <th className="px-4 py-2">Position</th>
                  <th className="px-4 py-2">Location</th>
                  <th className="px-4 py-2">Total Hours</th>
                  <th className="px-4 py-2">Method</th>
                  <th className="px-4 py-2">Rate</th>
                  <th className="px-4 py-2">Regular</th>
                  <th className="px-4 py-2">OT (1.5x)</th>
                  <th className="px-4 py-2">Total</th>
                  <th className="px-4 py-2 rounded-tr-lg">Weekly Salary</th>
                </tr>
              </thead>

              <tbody className="text-gray-700">
                {dataToDisplay.map((employee) => (
                  <tr 
                    key={employee.userId}
                    className={employee.status === "terminated" ? "bg-red-50" : ""}
                  >
                    <td className="border px-4 py-2">
                      {employee.companyName ||
                        `${employee.lastName}, ${employee.firstName}`}
                    </td>
                    <td className="border px-4 py-2">
                      <span className={employee.status === "terminated" ? "text-red-600 font-medium" : ""}>
                        {employee.status || "Active"}
                      </span>
                    </td>
                    <td className="border px-4 py-2 w-24 break-words">{employee.userId}</td>
                    <td className="border px-4 py-2">{employee.positions}</td>
                    <td className="border px-4 py-2">{employee.locations}</td>
                    <td className="border px-4 py-2">
                      {convertHoursToHM(employee.hours)}
                    </td>
                    <td className="border px-4 py-2">
                      {employee.payMethod}
                    </td>
                    <td className="border px-4 py-2">${employee.rate}</td>
                    <td className="border px-4 py-2">
                      {employee.payMethod === '1099' 
                        ? `$${(employee.rate * employee.regularHours).toFixed(2)}`
                        : '-'}
                    </td>
                    <td className="border px-4 py-2">
                      {employee.payMethod === '1099' 
                        ? `$${(employee.rate * 1.5 * employee.overtimeHours).toFixed(2)}`
                        : '-'}
                    </td>
                    <td className="border px-4 py-2">
                      ${employee.total.toFixed(2)}
                    </td>
                    <td className="border px-4 py-2">
                      {employee.weeklySalary ? `$${employee.weeklySalary.toFixed(2)}` : '-'}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    );
  }, [
    filteredEmployees,
    startDate,
    endDate,
    toggleLocationDropdown,
    selectedLocation,
    locationDropdownOpen,
    locations,
    minHours,
    maxHours,
    sliderValue,
    showApprovedOnly,
    exportToExcel,
    isLoading,
    showExportOptions,
    rangerInstance,
  ]);

  return (
    <div className="bg-[#1F2937] min-h-screen text-white p-8">
      {renderReports}
    </div>
  );
};

export default Reports;
