import React, { useState, useEffect, useContext, useMemo, useCallback } from "react";
import { GoogleMapsContext } from "./GoogleMapsProvider";
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  flexRender,
  getGroupedRowModel,
  getExpandedRowModel,
} from "@tanstack/react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faChevronDown } from "@fortawesome/free-solid-svg-icons";

function LocationsList({
  locations,
  onEditLocation,
  onLocationClick,
  selectedLocation,
  userRole,
  onEditRegion,
}) {
  const [sorting, setSorting] = useState([]);
  const [expanded, setExpanded] = useState({});
  const [autocomplete, setAutocomplete] = useState(null);
  const { isLoaded } = useContext(GoogleMapsContext);

  // Transform the locations data to include proper grouping
  const groupedLocations = useMemo(() => {
    // Group locations by region
    const grouped = locations.reduce((acc, loc) => {
      const region = loc.region || 'Unassigned';
      if (!acc[region]) {
        acc[region] = {
          region,
          regionManager: region === 'Unassigned' ? null : (loc.regionManager || 'No Manager'),
          regionManagerUid: region === 'Unassigned' ? null : loc.regionManagerUid,
          subRows: []
        };
      }
      acc[region].subRows.push(loc);
      return acc;
    }, {});

    // Convert to array and ensure Unassigned is first
    const result = Object.values(grouped);
    const unassignedIndex = result.findIndex(g => g.region === 'Unassigned');
    if (unassignedIndex > -1) {
      const unassigned = result.splice(unassignedIndex, 1)[0];
      result.unshift(unassigned);
    }

    return result;
  }, [locations]);

  useEffect(() => {
    if (
      isLoaded &&
      window.google &&
      window.google.maps &&
      window.google.maps.places
    ) {
      setAutocomplete(
        new window.google.maps.places.Autocomplete(
          document.getElementById("locationInput"),
          { types: ["geocode"] }
        )
      );
    }
  }, [isLoaded]);

  const handleEditLocation = useCallback((location) => {
    if (autocomplete) {
      onEditLocation(location);
    } else {
      console.error("Autocomplete is not available yet");
      // You might want to show a user-friendly message here
    }
  }, [autocomplete, onEditLocation]);

  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => row.region || "Unassigned",
        id: "region",
        header: "Region",
        cell: ({ row, getValue }) => {
          if (row.getCanExpand()) {
            return (
              <div
                className="flex items-center gap-2"
                onClick={row.getToggleExpandedHandler()}
              >
                <FontAwesomeIcon
                  icon={row.getIsExpanded() ? faChevronDown : faChevronRight}
                  className="w-4 h-4"
                />
                {getValue()} ({row.subRows.length})
              </div>
            );
          }
          return null;
        },
      },
      {
        accessorKey: "name",
        header: "Name/Address",
        cell: ({ row }) => {
          // Only render if it's not a group row
          if (!row.getCanExpand()) {
            return (
              <div className="flex items-center">
                <div className="ml-3">
                  <p className="text-gray-900 whitespace-no-wrap">
                    {row.original.name}
                  </p>
                  <p className="text-gray-900 whitespace-no-wrap">
                    {row.original.address}
                  </p>
                </div>
              </div>
            );
          }
          return null;
        },
      },
      {
        accessorKey: "regionManager",
        header: "Region Manager",
        cell: ({ row, getValue }) => {
          if (row.getCanExpand()) {
            return row.original.region === 'Unassigned' ? null : (getValue() || "No Manager Assigned");
          }
          return null;
        },
      },
      {
        accessorKey: "radius",
        header: "Radius",
        cell: ({ row, getValue }) => {
          if (!row.getCanExpand()) {
            return getValue();
          }
          return null;
        },
      },
      ...(userRole !== "consultant" && userRole !== "director"
        ? [
            {
              id: "actions",
              header: "Action",
              cell: ({ row }) => {
                if (!row.getCanExpand()) {
                  // For location rows
                  return (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleEditLocation(row.original);
                      }}
                      className="px-4 py-2 bg-blue-500 font-bold text-white rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75"
                      title="Edit"
                    >
                      Edit
                    </button>
                  );
                } else if (row.original.region !== "Unassigned") {
                  // For region rows (except Unassigned)
                  return (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        onEditRegion(row.original.region);
                      }}
                      className="px-4 py-2 bg-blue-500 font-bold text-white rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75"
                      title="Edit Region"
                    >
                      Edit Region
                    </button>
                  );
                }
                return null;
              },
            },
          ]
        : []),
    ],
    [userRole, handleEditLocation, onEditRegion]
  );

  const table = useReactTable({
    data: groupedLocations,
    columns,
    state: {
      sorting,
      expanded,
    },
    onSortingChange: setSorting,
    onExpandedChange: setExpanded,
    getSubRows: (row) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  return (
    <div className="overflow-x-auto rounded-lg">
      <table className="min-w-full leading-normal">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider cursor-pointer"
                  onClick={header.column.getToggleSortingHandler()}
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr
              key={row.id}
              className={`
                hover:bg-gray-50
                ${!row.getCanExpand() && selectedLocation === row.original.name ? "bg-blue-100" : "bg-white"}
                ${row.getCanExpand() ? "bg-gray-100" : ""}
                ${!row.getCanExpand() ? "cursor-pointer hover:bg-gray-50" : ""}
              `}
              onClick={() => !row.getCanExpand() && onLocationClick(row.original)}
            >
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  className={`
                    px-5 py-5 border-b border-gray-200 text-sm
                    ${row.getCanExpand() ? "font-semibold text-gray-700" : "text-gray-900"}
                  `}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default LocationsList;
